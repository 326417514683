import * as _ from 'underscore';
var GracefulError = /** @class */ (function () {
    function GracefulError() {
    }
    /*
  
     */
    GracefulError.prototype.processGQLResponse = function (response) {
        var errorsResponseArray = response === null || response === void 0 ? void 0 : response.errors;
        var result = [];
        if (errorsResponseArray && errorsResponseArray.length > 0) {
            _.each(errorsResponseArray, function (item, index) {
                var _a, _b, _c, _d;
                result.push({
                    message: ((_b = (_a = item.extensions) === null || _a === void 0 ? void 0 : _a.originalError) === null || _b === void 0 ? void 0 : _b.message) ? String(item.extensions.originalError.message) : item.message,
                    code: ((_d = (_c = item.extensions) === null || _c === void 0 ? void 0 : _c.originalError) === null || _d === void 0 ? void 0 : _d.code) ? String(item.extensions.originalError.code) : undefined,
                    source: item || undefined
                });
            });
        }
        return result;
    };
    return GracefulError;
}());
export default new GracefulError();
