var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import styles from './styles-shortlink-list.less';
import * as React from 'react';
import * as _ from 'underscore';
import Input from '../../components/input';
import ShortlinkListItem from '../../components/shortlink-list-item';
import shortlinkQueries from '../../js/shortlink.gql';
import classNames from 'classnames';
import dateTimeTools from '../../js/datetime.tools';
import RadioGroup from '../../components/radio-group';
import DropdownMenu from '../../components/dropdown-menu';
import MenuItem from '../../components/menu-item';
import Scroller from '../../components/scroller';
import clipboardTools from '../../js/clipboard.tools';
import linkTools from '../../js/link.tools';
import { CompactIcon, FullIcon, Search } from '../../components/icons';
import UrlEdit from '../UrlEdit';
import Snackbar from '../../components/snackbar';
import { getCookie, setCookie } from '../../js/utils';
var LoadMode;
(function (LoadMode) {
    LoadMode["append"] = "append";
    LoadMode["replace"] = "replace";
    LoadMode["none"] = "none";
})(LoadMode || (LoadMode = {}));
export var ShortlinkListSubsection;
(function (ShortlinkListSubsection) {
    ShortlinkListSubsection["all"] = "created";
    ShortlinkListSubsection["snoozed"] = "snoozed";
})(ShortlinkListSubsection || (ShortlinkListSubsection = {}));
export var ShortlinkListContentDisplay;
(function (ShortlinkListContentDisplay) {
    ShortlinkListContentDisplay["compact"] = "compact";
    ShortlinkListContentDisplay["full"] = "full";
})(ShortlinkListContentDisplay || (ShortlinkListContentDisplay = {}));
var ShortlinkList = /** @class */ (function (_super) {
    __extends(ShortlinkList, _super);
    function ShortlinkList(props) {
        var _this = _super.call(this, props) || this;
        var contentDisplay = getCookie('content-display') || ShortlinkListContentDisplay.compact;
        _this.state = {
            shortlinks: [],
            groupedShortlinks: [],
            searchQuery: '',
            pointer: 0,
            limit: props.limit || 30,
            contentDisplay: contentDisplay,
            staleResults: false,
            isLoading: LoadMode.none,
            contextMenu: {
                key: -1,
                top: -99999,
                left: -999999,
                show: false
            },
            selected: {
                shortlink: null,
                loading: false,
                errorState: null,
                successState: null
            }
        };
        _this.contextMenuRef = React.createRef();
        _.bindAll.apply(_, __spreadArray([_this], _.functions(_this), false));
        return _this;
    }
    ShortlinkList.prototype.onSearch = function (value, event, isClear) {
        this.loadShortlinks(LoadMode.replace);
    };
    ShortlinkList.prototype.onSearchQueryChange = function (value, event, isClear) {
        this.setState({ searchQuery: value, staleResults: true });
    };
    ShortlinkList.prototype.componentDidMount = function () {
        this.loadShortlinks(LoadMode.replace);
    };
    ShortlinkList.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        if (this.getSubsection() != this.getSubsection(prevProps.router.location.pathname))
            this.loadShortlinks(LoadMode.replace);
    };
    ShortlinkList.prototype.getSubsection = function (_pathname) {
        var pathname = _pathname ? _pathname : this.props.router.location.pathname;
        if (pathname == '/app')
            return ShortlinkListSubsection.all;
        if (pathname == '/app/snoozed')
            return ShortlinkListSubsection.snoozed;
    };
    ShortlinkList.prototype.loadShortlinks = function (load) {
        return __awaiter(this, void 0, void 0, function () {
            var params, result, newShortlinks, groupedResult;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.setState({
                            isLoading: load,
                            staleResults: load == LoadMode.replace ? true : false
                        });
                        if (this.getSubsection() == ShortlinkListSubsection.snoozed) {
                            params = {
                                search: this.state.searchQuery || undefined,
                                skip: load == LoadMode.replace ? 0 : this.state.pointer,
                                limit: this.state.limit,
                                isSnooze: true,
                                sort: 'snooze.awake',
                                order: '1'
                            };
                        }
                        else {
                            params = {
                                search: this.state.searchQuery || undefined,
                                skip: load == LoadMode.replace ? 0 : this.state.pointer,
                                limit: this.state.limit
                            };
                        }
                        return [4 /*yield*/, shortlinkQueries.getUserShortlinks(params)];
                    case 1:
                        result = _a.sent();
                        newShortlinks = load == LoadMode.replace ? result : Array().concat(this.state.shortlinks, result);
                        groupedResult = this.groupShortlinks(newShortlinks);
                        console.log(load, result);
                        this.setState({
                            pointer: load == LoadMode.replace ? result.length : this.state.shortlinks.length + result.length,
                            shortlinks: newShortlinks,
                            groupedShortlinks: groupedResult,
                            staleResults: false
                        });
                        _.delay(function () {
                            _this.setState({
                                isLoading: LoadMode.none
                            });
                        }, 250);
                        return [2 /*return*/];
                }
            });
        });
    };
    ShortlinkList.prototype.groupShortlinks = function (shortlinks) {
        var dateGroupKey = (this.getSubsection() == ShortlinkListSubsection.snoozed) ? ['snooze', 'awake'] : ['createdAt'];
        var _enrichedLabelGroups = dateTimeTools.groupDatedItems(shortlinks, dateGroupKey);
        var groupedShortlinks = [];
        _.each(_enrichedLabelGroups, function (item, index, array) {
            if (index == 0 || array[index - 1].group != item.group) {
                groupedShortlinks.push({ isSubheader: true, group: item.group, originalIndex: -1 });
            }
            groupedShortlinks.push(_.extend({ timestamp: item.createdAt || item.updatedAt || null, originalIndex: index }, item));
        });
        return groupedShortlinks;
    };
    ShortlinkList.prototype.removeCachedShortlink = function (id) {
        var index = _.findIndex(this.state.shortlinks, { _id: id });
        console.log(id, index);
        var updatedShortlinks = this.state.shortlinks;
        updatedShortlinks.splice(index, 1);
        var groupedShortlinks = this.groupShortlinks(updatedShortlinks);
        this.setState({
            pointer: this.state.pointer - 1,
            shortlinks: updatedShortlinks,
            groupedShortlinks: groupedShortlinks,
            staleResults: false
        });
    };
    ShortlinkList.prototype.updateCachedShortlink = function (shortlink) {
        var index = _.findIndex(this.state.shortlinks, { _id: shortlink._id });
        console.log('Updating shortlink', index, this.state.shortlinks[index]);
        var updatedShortlinks = this.state.shortlinks;
        updatedShortlinks[index] = shortlink;
        var groupedShortlinks = this.groupShortlinks(updatedShortlinks);
        this.setState({
            shortlinks: updatedShortlinks,
            groupedShortlinks: groupedShortlinks,
            staleResults: false
        });
    };
    ShortlinkList.prototype.handleInternalNavigate = function (key) {
        if (key == ShortlinkListSubsection.all)
            this.props.navigate('/app');
        if (key == ShortlinkListSubsection.snoozed)
            this.props.navigate('/app/snoozed');
    };
    ShortlinkList.prototype.handleContentDisplayChange = function (key) {
        this.setState({
            contentDisplay: key
        });
        setCookie('content-display', key, 180);
    };
    ShortlinkList.prototype.handleContextClick = function (key, element) {
        var _this = this;
        _.defer(function () {
            var top = element.offsetTop + element.offsetHeight;
            var left = element.offsetLeft + element.offsetWidth;
            _this.setState({
                contextMenu: {
                    show: true,
                    top: -top,
                    left: -left,
                    key: key,
                }
            });
        });
    };
    ShortlinkList.prototype.handleContextPortal = function (isAppearing) {
        var contextMenuParams = this.contextMenuRef.current.getClientRects();
        this.setState({
            contextMenu: {
                show: true,
                top: Math.abs(this.state.contextMenu.top) - contextMenuParams[0].height,
                left: Math.abs(this.state.contextMenu.left) - contextMenuParams[0].width,
                key: this.state.contextMenu.key,
            }
        });
    };
    ShortlinkList.prototype.handleRemoveSnoozeTimer = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var id, result;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        id = (_a = this.state.shortlinks[this.state.contextMenu.key]) === null || _a === void 0 ? void 0 : _a._id;
                        return [4 /*yield*/, shortlinkQueries.deleteShortlinkSnoozeTimer([id])];
                    case 1:
                        result = _b.sent();
                        if (result && result.length > 0) {
                            _.each(result, function (item) {
                                _this.removeCachedShortlink(item._id);
                            });
                        }
                        _.defer(this.resetContextMenu);
                        return [2 /*return*/];
                }
            });
        });
    };
    ShortlinkList.prototype.handleDeleteShortlink = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var id, result;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        id = (_a = this.state.shortlinks[this.state.contextMenu.key]) === null || _a === void 0 ? void 0 : _a._id;
                        return [4 /*yield*/, shortlinkQueries.deleteShortlink(id)];
                    case 1:
                        result = _b.sent();
                        console.log(result);
                        if (result && result._id) {
                            this.removeCachedShortlink(result._id);
                        }
                        _.defer(this.resetContextMenu);
                        return [2 /*return*/];
                }
            });
        });
    };
    ShortlinkList.prototype.resetContextMenu = function () {
        this.setState({
            contextMenu: {
                key: -1,
                top: -99999,
                left: -999999,
                show: false
            }
        });
    };
    ShortlinkList.prototype.handleCopyClick = function (key) {
        if (!this.state.shortlinks[key])
            return;
        var hash = this.state.shortlinks[key].hash;
        var descriptor = this.state.shortlinks[key].descriptor;
        var shortlink = this.state.shortlinks[key].descriptor ?
            linkTools.generateDescriptiveShortlink(this.state.shortlinks[key].descriptor) :
            linkTools.generateShortlinkFromHash(this.state.shortlinks[key].hash);
        clipboardTools.copy(shortlink);
    };
    ShortlinkList.prototype.handleScroll = function (scrollTop, scrollHeight, clientHeight, direction) {
        var isThreshold = (scrollTop + clientHeight) > (scrollHeight * 0.96);
        if (isThreshold &&
            this.state.isLoading == LoadMode.none &&
            direction > 0) {
            console.log('scroll load', scrollTop);
            this.loadShortlinks(LoadMode.append);
        }
    };
    ShortlinkList.prototype.handleUrlChange = function (shortlink) {
        return __awaiter(this, void 0, void 0, function () {
            var result, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!shortlink) {
                            this.setState({
                                selected: _.defaults({
                                    errorState: 'Couldn’t save this shortlink'
                                }, this.state.selected)
                            });
                            console.error('No shortlink passed to onChange method from module UrlEdit', shortlink);
                        }
                        this.setState({ selected: _.defaults({ loading: true }, this.state.selected) });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        console.log('sending ', shortlink);
                        return [4 /*yield*/, shortlinkQueries.updateShortlink(shortlink._id, shortlink)];
                    case 2:
                        result = _a.sent();
                        console.log(result);
                        this.updateCachedShortlink(result);
                        this.setState({
                            selected: {
                                shortlink: null,
                                loading: false,
                                errorState: null,
                                successState: 'Shortlink updated'
                            }
                        });
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        console.error(error_1);
                        this.setState({
                            selected: _.defaults({
                                loading: false,
                                errorState: error_1.message || 'Something went wrong'
                            }, this.state.selected)
                        });
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ShortlinkList.prototype.handleSelectShortlink = function () {
        this.resetContextMenu();
        if (!this.state.shortlinks[this.state.contextMenu.key])
            return;
        this.setState({
            selected: _.defaults({
                shortlink: _.omit(this.state.shortlinks[this.state.contextMenu.key], 'group')
            }, this.state.selected)
        });
    };
    ShortlinkList.prototype._clearSelected = function () {
        this.setState({
            selected: _.defaults({
                shortlink: null
            }, this.state.selected)
        });
    };
    ShortlinkList.prototype._clearSelectedErrorState = function () {
        this.setState({
            selected: _.defaults({ errorState: null }, this.state.selected)
        });
    };
    ShortlinkList.prototype._clearSelectedSuccessState = function () {
        this.setState({
            selected: _.defaults({ successState: null }, this.state.selected)
        });
    };
    ShortlinkList.prototype.render = function () {
        var _a;
        var _this = this;
        var globalClass = "".concat(styles.wrapperClass, "_shortlink-list-app");
        var listClasses = classNames((_a = {},
            _a["".concat(globalClass)] = true,
            _a["".concat(globalClass, "_loading")] = this.state.staleResults,
            _a));
        return (React.createElement("div", { className: "".concat(listClasses) },
            React.createElement("div", { className: "".concat(globalClass, "__search") },
                React.createElement(Input, { onChange: this.onSearchQueryChange, onDebouncedChange: this.onSearch, value: this.state.searchQuery, placeholder: 'Search your links', rightIcon: Search }),
                React.createElement("div", { className: "".concat(globalClass, "__search__controls") },
                    React.createElement(RadioGroup, { items: [
                            { label: 'All links', key: ShortlinkListSubsection.all },
                            { label: 'Snoozed', key: ShortlinkListSubsection.snoozed }
                        ], value: this.getSubsection(), onChange: function (key) { _this.handleInternalNavigate(key); }, fullWidth: true }),
                    React.createElement(RadioGroup, { items: [
                            { icon: CompactIcon, key: ShortlinkListContentDisplay.compact },
                            { icon: FullIcon, key: ShortlinkListContentDisplay.full }
                        ], value: this.state.contentDisplay, onChange: this.handleContentDisplayChange }))),
            React.createElement(Scroller, { className: "".concat(globalClass, "__scroller"), onScroll: this.handleScroll },
                React.createElement("div", { className: "".concat(globalClass, "__list") },
                    this.state.groupedShortlinks.map(function (item, index, array) {
                        if (item.isSubheader) {
                            return React.createElement("span", { key: index, className: "".concat(globalClass, "__subheader") }, item.group);
                        }
                        else {
                            return (React.createElement(ShortlinkListItem, __assign({ key: index, timestamp: item.timestamp }, _.omit(item, 'hash', 'location'), { hash: item.hash, location: item.location, showDescription: _this.state.contentDisplay == ShortlinkListContentDisplay.full, onCopyClick: function () { return _this.handleCopyClick(item.originalIndex); }, onContextClick: function (elem) { _this.handleContextClick(item.originalIndex, elem); } })));
                        }
                    }),
                    (this.state.isLoading == LoadMode.append) &&
                        React.createElement(ShortlinkListItem.Loading, null),
                    this.state.shortlinks.length == 0 && this.state.isLoading == LoadMode.none &&
                        React.createElement("div", { className: "".concat(globalClass, "__list-footer_nothing") }, "Nothing found"),
                    this.state.isLoading == LoadMode.none && this.state.shortlinks.length > 0 &&
                        React.createElement("div", { className: "".concat(globalClass, "__list-footer_empty") }, "\u00A0"),
                    React.createElement(DropdownMenu, { divRef: this.contextMenuRef, show: this.state.contextMenu.show, onClose: this.resetContextMenu, onEnter: this.handleContextPortal, style: { top: this.state.contextMenu.top, left: this.state.contextMenu.left } },
                        React.createElement(MenuItem, { label: 'Delete', onClick: this.handleDeleteShortlink }),
                        React.createElement(MenuItem.Separator, null),
                        this.getSubsection() == ShortlinkListSubsection.snoozed && React.createElement(MenuItem, { label: 'Remove snooze', onClick: this.handleRemoveSnoozeTimer }),
                        React.createElement(MenuItem, { label: 'Edit shortlink', onClick: this.handleSelectShortlink })))),
            this.state.selected.shortlink &&
                React.createElement(UrlEdit, { onChange: this.handleUrlChange, onCancel: this._clearSelected, shortlink: this.state.selected.shortlink, isLoading: this.state.selected.loading, userContextName: this.props.context.user.userTag }),
            React.createElement("div", { className: "".concat(globalClass, "__snackbar-container") },
                this.state.selected.errorState &&
                    React.createElement(Snackbar, { className: "".concat(globalClass, "__shortlink-list-error"), message: this.state.selected.errorState, canDismiss: true, onDismiss: this._clearSelectedErrorState }),
                this.state.selected.successState &&
                    React.createElement(Snackbar, { className: "".concat(globalClass, "__shortlink-list-success"), message: this.state.selected.successState, canDismiss: true, timer: 2000, onDismiss: this._clearSelectedSuccessState }))));
    };
    return ShortlinkList;
}(React.Component));
export default ShortlinkList;
