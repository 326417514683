import styles from './styles-shortlink-list-item.less';
import * as React from 'react';
import * as _ from 'underscore';
import classNames from 'classnames';
import LinkTools from '../../js/link.tools';
import Link, { LinkColors } from '../link';
import Button, { ButtonSize, ButtonType } from '../button';
import { MoreVertical } from '../icons';
var ActionLabels;
(function (ActionLabels) {
    ActionLabels["copy"] = "Copy";
    ActionLabels["copied"] = "Copied";
})(ActionLabels || (ActionLabels = {}));
var ShortlinkListItem = function (_a) {
    var _b;
    var location = _a.location, hash = _a.hash, descriptor = _a.descriptor, timestamp = _a.timestamp, siteTitle = _a.siteTitle, siteDescription = _a.siteDescription, urlMetadata = _a.urlMetadata, snooze = _a.snooze, tags = _a.tags, showDescription = _a.showDescription, onContextClick = _a.onContextClick, onCopyClick = _a.onCopyClick;
    var globalClass = "".concat(styles.wrapperClass, "_shortlink-item");
    var shortlinkItemClasses = classNames((_b = {},
        _b["".concat(globalClass)] = true,
        _b));
    var shortlink = (descriptor === null || descriptor === void 0 ? void 0 : descriptor.descriptionTag) && descriptor.descriptionTag != '' ?
        LinkTools.generateDescriptiveShortlink(descriptor) :
        LinkTools.generateShortlinkFromHash(hash);
    var displayShortlink = (descriptor === null || descriptor === void 0 ? void 0 : descriptor.descriptionTag) && descriptor.descriptionTag != '' ?
        LinkTools.makeDisplayShortlink(descriptor) :
        LinkTools.makeDisplayShortlink(hash);
    var favicon = ((urlMetadata === null || urlMetadata === void 0 ? void 0 : urlMetadata.favicons) && (urlMetadata === null || urlMetadata === void 0 ? void 0 : urlMetadata.favicons[0]) && (urlMetadata === null || urlMetadata === void 0 ? void 0 : urlMetadata.favicons[0].src)) ? urlMetadata === null || urlMetadata === void 0 ? void 0 : urlMetadata.favicons[0].src : '/assets/default-favicon.png';
    function handleCopyClick(event) {
        if (_.isFunction(onCopyClick))
            onCopyClick();
        return void 0;
    }
    function handleContextClick(event, elem) {
        if (_.isFunction(onContextClick))
            onContextClick(elem || null);
        return void 0;
    }
    var noDescription = (siteDescription == undefined) ? "" : "".concat(globalClass, "__display-full-link_no-description");
    return (React.createElement("div", { className: "".concat(shortlinkItemClasses) },
        React.createElement(Link, { className: "".concat(globalClass, "__display-full-link ").concat(noDescription), href: location, colorScheme: LinkColors.USER },
            React.createElement("div", { className: "".concat(globalClass, "__display-full-link__main") },
                React.createElement("div", { className: "".concat(globalClass, "__display-full-link__title") }, siteTitle)),
            React.createElement("div", { className: "".concat(globalClass, "__display-full-link__subheader") },
                React.createElement("img", { className: "".concat(globalClass, "__display-full-link__favicon"), src: favicon }),
                React.createElement("span", { className: "".concat(globalClass, "__display-full-link__subheader__span") }, LinkTools.makeDisplayUrl(location))),
            showDescription && siteDescription &&
                React.createElement("div", { className: "".concat(globalClass, "__display-full-link__description") }, siteDescription)),
        React.createElement("div", { className: "".concat(globalClass, "__shortlink-meta") },
            React.createElement(Link, { onClick: handleCopyClick, className: "".concat(globalClass, "__display-shortlink"), suffix: "".concat(ActionLabels.copy, "+").concat(ActionLabels.copied) },
                React.createElement("span", { className: "".concat(globalClass, "__shortlink") }, displayShortlink)),
            React.createElement(Button, { icon: MoreVertical, size: ButtonSize.SMALL, type: ButtonType.GHOST, onClick: handleContextClick }))));
};
ShortlinkListItem.Loading = function (props) {
    var globalClass = styles.wrapperClass + '_shortlink-item';
    return (React.createElement("div", { className: "".concat(globalClass, " ").concat(globalClass, "_loading") },
        React.createElement("div", { className: "".concat(globalClass, "__display-full-link") },
            React.createElement("div", { className: "".concat(globalClass, "__display-full-link__main") },
                React.createElement("div", { className: "".concat(globalClass, "__display-full-link__title") }, "\u00A0")),
            React.createElement("div", { className: "".concat(globalClass, "__display-full-link__subheader") },
                React.createElement("img", { className: "".concat(globalClass, "__display-full-link__favicon"), src: "/assets/default-favicon.png" }),
                React.createElement("span", { className: "".concat(globalClass, "__display-full-link__subheader__span") }, "\u00A0")))));
};
export default ShortlinkListItem;
