var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as _ from 'underscore';
import proxyStorage from './proxy-storage.webapp';
import linkTools from './link.tools';
import GQLShortlinkQuery from './shortlink.gql';
import { deleteURLQueryParam } from './utils';
export var CacheMode;
(function (CacheMode) {
    CacheMode["local"] = "local";
    CacheMode["remote"] = "remote";
})(CacheMode || (CacheMode = {}));
var ShortlinkCache = /** @class */ (function () {
    function ShortlinkCache() {
        var _now = new Date();
        this.dateThreshold = new Date(_now.setMonth(_now.getMonth() - 1));
        this.storage = [];
        _.defer(this.purgeOutdatedShortlinks.bind(this));
    }
    ShortlinkCache.prototype.setStorage = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (this.mode == CacheMode.local) {
                            this.storagePromise = this.getAllFromLocalStorage();
                        }
                        else {
                            this.storagePromise = this.getAllFromRemote();
                        }
                        _a = this;
                        return [4 /*yield*/, this.storagePromise];
                    case 1:
                        _a.storage = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ShortlinkCache.prototype.awaitStorage = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.storagePromise];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, this.storage];
                }
            });
        });
    };
    ShortlinkCache.prototype.getStorage = function () {
        return this.storage;
    };
    ShortlinkCache.prototype.checkShortlink = function (args) {
        var result = _.findWhere(this.storage, args);
        return result || null;
    };
    ShortlinkCache.prototype.storeShortlink = function (shortlink) {
        this.storage = Array().concat([shortlink], this.storage);
        if (this.mode == CacheMode.local) {
            this.storeLocalStorage(shortlink);
        }
    };
    ShortlinkCache.prototype.checkLocalStorageObject = function (object) {
        return (_.isObject(object) &&
            !_.isEmpty(object) &&
            _.keys(object).includes('location'));
    };
    ShortlinkCache.prototype.purgeOutdatedShortlinks = function () {
        return __awaiter(this, void 0, void 0, function () {
            var items, forcePurge, trailingItems;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, proxyStorage.getAllItems(null)];
                    case 1:
                        items = _a.sent();
                        if (!items)
                            return [2 /*return*/];
                        forcePurge = linkTools.queryUrlSearchParams(['purge'], window.location.search);
                        if (forcePurge[0] == 'true') {
                            deleteURLQueryParam('purge');
                        }
                        items = _.filter(items, function (item) {
                            return _this.checkLocalStorageObject(item);
                        });
                        items = _.sortBy(items, function (item) {
                            return Date.now() - (new Date(item.createdAt)).valueOf();
                        });
                        trailingItems = _.last(items, items.length - 30);
                        _.each(trailingItems, function (item) {
                            proxyStorage.removeItem(item.key);
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    ShortlinkCache.prototype.storeLocalStorage = function (args) {
        if (!proxyStorage.canUse())
            return false;
        var urlKey = encodeURI(args.location);
        var storageItem = __assign(__assign({}, args), { createdAt: (new Date()).toISOString() });
        proxyStorage.setItem(urlKey, JSON.stringify(storageItem)).catch(function (err) { console.error(err); });
        return true;
    };
    ShortlinkCache.prototype.checkLocalStorage = function (args) {
        return __awaiter(this, void 0, void 0, function () {
            var urlKey, existingShortlink;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!proxyStorage.canUse())
                            return [2 /*return*/, null];
                        urlKey = encodeURI(args.location);
                        return [4 /*yield*/, proxyStorage.getItem(urlKey)];
                    case 1:
                        existingShortlink = _a.sent();
                        if (existingShortlink != null) {
                            return [2 /*return*/, JSON.parse(existingShortlink)];
                        }
                        else {
                            return [2 /*return*/, null];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ShortlinkCache.prototype.getAllFromLocalStorage = function (limit) {
        return __awaiter(this, void 0, void 0, function () {
            var storageContent, result;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, proxyStorage.getAllItems(null)];
                    case 1:
                        storageContent = _a.sent();
                        result = [];
                        if (_.isEmpty(storageContent))
                            return [2 /*return*/, result];
                        _.each(storageContent, function (item) {
                            if (_this.checkLocalStorageObject(item)) {
                                result.push(item);
                            }
                        });
                        result = _.sortBy(result, function (item) {
                            return Date.now() - (new Date(item.createdAt)).valueOf();
                        });
                        if (limit)
                            return [2 /*return*/, _.first(result, limit)];
                        return [2 /*return*/, result];
                }
            });
        });
    };
    ShortlinkCache.prototype.getAllFromRemote = function (limit) {
        if (limit === void 0) { limit = 30; }
        return __awaiter(this, void 0, void 0, function () {
            var storageContent, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, GQLShortlinkQuery.getUserShortlinks({
                                limit: limit
                            })];
                    case 1:
                        storageContent = _a.sent();
                        return [2 /*return*/, storageContent];
                    case 2:
                        error_1 = _a.sent();
                        console.error(error_1);
                        return [2 /*return*/, []];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return ShortlinkCache;
}());
export default new ShortlinkCache();
