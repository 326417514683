import styles from './styles-url-edit.less';
import * as React from 'react';
import * as _ from 'underscore';
import classNames from 'classnames';
import Input from '../../components/input';
import { LinkIcon } from '../../components/icons';
import Button, { ButtonSize, ButtonType } from '../../components/button';
import { modifyURLSlug } from '../../js/utils';
var UrlEdit = function (props) {
    var _a;
    var _b, _c;
    var _d = React.useState(props.shortlink), shortlink = _d[0], setShortlink = _d[1];
    var userTag = ((_b = shortlink.descriptor) === null || _b === void 0 ? void 0 : _b.userTag) || props.userContextName;
    var globalClass = styles.wrapperClass + '_url-edit';
    var appClasses = classNames((_a = {},
        _a["".concat(globalClass)] = true,
        _a));
    function updateShortlink(chunk) {
        if (props.isLoading)
            return;
        setShortlink(_.defaults({}, chunk, shortlink));
    }
    function canSave() {
        return (shortlink.siteTitle && shortlink.siteTitle != '' &&
            shortlink.location && shortlink.location != '');
    }
    return (React.createElement("div", { className: "".concat(appClasses) },
        React.createElement("div", { className: "".concat(globalClass, "__url-form") },
            React.createElement(Input, { className: "".concat(globalClass, "__title-input"), value: shortlink.siteTitle, onChange: function (value, event) { return updateShortlink({ siteTitle: value }); }, label: "Title", placeholder: "Set shortlink title" }),
            React.createElement(Input, { className: "".concat(globalClass, "__location-input"), value: shortlink.location, leftIcon: LinkIcon, onChange: function (value, event) { return updateShortlink({ location: value }); }, label: "Url", placeholder: "Set url" }),
            React.createElement(Input, { className: "".concat(globalClass, "__slug-input"), value: ((_c = shortlink.descriptor) === null || _c === void 0 ? void 0 : _c.descriptionTag) || '', prefix: "".concat(userTag, "@"), onChange: function (value, event) {
                    return updateShortlink({ descriptor: { userTag: userTag, descriptionTag: modifyURLSlug(value) } });
                }, label: "Custom shortlink", placeholder: "Choose custom slug" }),
            React.createElement(Input, { className: "".concat(globalClass, "__description-input"), value: shortlink.siteDescription, onChange: function (value, event) { return updateShortlink({ siteDescription: value }); }, label: "Description", placeholder: "Set link description" }),
            React.createElement("div", { className: "".concat(globalClass, "__url-form__controls") },
                React.createElement(Button, { size: ButtonSize.SMALL, type: ButtonType.SECONDARY, label: 'Cancel', onClick: function () { return props.onCancel(); }, fullWidth: true }),
                React.createElement(Button, { isDisabled: !canSave(), isLoading: props.isLoading, size: ButtonSize.SMALL, type: ButtonType.PRIMARY, label: 'Save', onClick: function () { return props.onChange(shortlink); }, fullWidth: true })))));
};
export default UrlEdit;
