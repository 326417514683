import styles from './styles-dropdown-menu.less';
import * as React from 'react';
import { CSSTransition } from 'react-transition-group';
import * as _ from 'underscore';
import classNames from 'classnames';
export var DropdownPosition;
(function (DropdownPosition) {
    DropdownPosition["top"] = "top";
    DropdownPosition["left"] = "left";
    DropdownPosition["bottom"] = "bottom";
    DropdownPosition["right"] = "right";
    DropdownPosition["wide"] = "wide";
})(DropdownPosition || (DropdownPosition = {}));
var DropdownMenu = function (_a) {
    var _b;
    var show = _a.show, children = _a.children, onClose = _a.onClose, onEnter = _a.onEnter, position = _a.position, className = _a.className, divRef = _a.divRef, style = _a.style;
    var globalClass = "".concat(styles.wrapperClass, "_dropdown-menu");
    var dropdownMenuClasses = classNames((_b = {},
        _b["".concat(globalClass)] = true,
        _b["".concat(globalClass, "_").concat(position[0])] = true,
        _b["".concat(globalClass, "_").concat(position[1])] = true,
        _b["".concat(className)] = className,
        _b));
    var transitionDuration = parseInt(styles.appearTransition);
    function handleClickOutside(event) {
        if (_.isFunction(onClose))
            onClose();
    }
    function handleCSSEnter(isAppearing) {
        if (_.isFunction(onEnter))
            onEnter(isAppearing);
    }
    React.useEffect(function () {
        if (show) {
            _.delay(function () { return document.addEventListener('click', handleClickOutside); }, transitionDuration);
        }
        else {
            document.removeEventListener('click', handleClickOutside);
        }
        return function () { document.removeEventListener('click', handleClickOutside); };
    }, [show]);
    return (React.createElement(React.Fragment, null,
        React.createElement(CSSTransition, { nodeRef: divRef, in: show, timeout: transitionDuration, classNames: "".concat(globalClass), onEnter: handleCSSEnter },
            React.createElement("div", { ref: divRef, className: "".concat(dropdownMenuClasses), style: style }, children))));
};
DropdownMenu.defaultProps = {
    position: [DropdownPosition.bottom, DropdownPosition.wide],
    divRef: React.createRef(),
    style: {}
};
export default DropdownMenu;
