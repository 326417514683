var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { validateURL } from './utils';
import config from './config';
import GQLRequest from './request-wrapper.gql';
var GQLShortlinkQuery = /** @class */ (function () {
    function GQLShortlinkQuery() {
        this.fullShortlinkProperties = "\n  _id\n  hash\n  location\n  descriptor {\n    userTag\n    descriptionTag\n  }\n  owner\n  urlMetadata\n  snooze {\n    awake\n    description\n  }\n  createdAt\n  updatedAt\n  siteTitle\n  siteDescription\n  ";
        this.queryUrl = config.serviceUrl + '/api';
        this.gqlClient = new GQLRequest({
            baseURL: this.queryUrl,
            method: 'POST',
            headers: {}
        });
    }
    GQLShortlinkQuery.prototype.createShortlink = function (location) {
        return __awaiter(this, void 0, void 0, function () {
            var query, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (validateURL(location) == false) {
                            throw new Error("Not a valid URL: '".concat(location, "'"));
                        }
                        query = "\n    mutation createShortlinkWithVars (\n      $location: String!\n    ){\n      createShortlink(location: $location) {\n        _id\n        hash\n        location\n        descriptor {\n          userTag\n          descriptionTag\n        }\n      }\n    }\n    ";
                        return [4 /*yield*/, this.gqlClient.request(query, { location: location })];
                    case 1:
                        response = _a.sent();
                        console.log('[GQL] createShortlink\n', response);
                        return [2 /*return*/, response.createShortlink];
                }
            });
        });
    };
    GQLShortlinkQuery.prototype.createShortlinkDescriptor = function (_a) {
        var userTag = _a.userTag, descriptionTag = _a.descriptionTag, location = _a.location, hash = _a.hash;
        return __awaiter(this, void 0, void 0, function () {
            var query, response;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!descriptionTag || !location)
                            return [2 /*return*/, null];
                        query = "\n    mutation createDescriptiveShortlinkWithVars(\n      $userTag: String\n      $descriptionTag: String!\n      $location: String!\n      $hash: String\n    ) {\n      createDescriptiveShortlink(\n        userTag: $userTag, \n        descriptionTag: $descriptionTag, \n        location: $location, \n        hash: $hash\n      ) {\n        _id\n        hash\n        location\n        descriptor {\n          userTag\n          descriptionTag\n        }\n      }\n    }\n    ";
                        return [4 /*yield*/, this.gqlClient.request(query, { userTag: userTag, descriptionTag: descriptionTag, location: location, hash: hash })];
                    case 1:
                        response = _b.sent();
                        console.log('[GQL] createShortlinkDescriptor\n', response);
                        return [2 /*return*/, response.createDescriptiveShortlink];
                }
            });
        });
    };
    GQLShortlinkQuery.prototype.getUserShortlinks = function (_a) {
        var limit = _a.limit, skip = _a.skip, sort = _a.sort, order = _a.order, search = _a.search, isSnooze = _a.isSnooze;
        return __awaiter(this, void 0, void 0, function () {
            var query, response;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        query = "\n    query getUserShortlinksWithVars (\n      $limit: Int\n      $skip: Int\n      $sort: String\n      $order: String\n      $search: String\n      $isSnooze: Boolean\n    ){\n      getUserShortlinks(\n        args: {\n          limit: $limit\n          skip: $skip\n          sort: $sort\n          order: $order\n          search: $search\n          isSnooze: $isSnooze\n        }\n      ) {\n        ".concat(this.fullShortlinkProperties, "\n      }\n    }\n    ");
                        return [4 /*yield*/, this.gqlClient.request(query, { limit: limit, skip: skip, sort: sort, order: order, search: search, isSnooze: isSnooze })];
                    case 1:
                        response = _b.sent();
                        console.log('[GQL] getUserShortlinks\n', response);
                        return [2 /*return*/, response.getUserShortlinks];
                }
            });
        });
    };
    GQLShortlinkQuery.prototype.createOrUpdateShortlinkTimer = function (args) {
        return __awaiter(this, void 0, void 0, function () {
            var query, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        query = "\n    mutation createOrUpdateShortlinkTimerWithVars(\n      $location: String\n      $hash: String\n      $id: String\n      $standardTimer: String\n      $customDay: Mixed\n      $customTime: Mixed\n      $baseDateISOString: String\n    ) {\n      createOrUpdateShortlinkTimer (\n        args: {\n          location: $location\n          hash: $hash\n          id: $id\n          standardTimer: $standardTimer\n          customDay: $customDay\n          customTime: $customTime\n          baseDateISOString: $baseDateISOString\n        }\n      ) {\n        _id\n        hash\n        location\n        snooze {\n          awake\n          description\n        }\n        descriptor {\n          userTag\n          descriptionTag\n        }\n      }\n    }\n    ";
                        return [4 /*yield*/, this.gqlClient.request(query, args)];
                    case 1:
                        response = _a.sent();
                        console.log('[GQL] createOrUpdateShortlinkTimer\n', response);
                        return [2 /*return*/, response.createOrUpdateShortlinkTimer];
                }
            });
        });
    };
    GQLShortlinkQuery.prototype.deleteShortlinkSnoozeTimer = function (ids) {
        return __awaiter(this, void 0, void 0, function () {
            var query, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        query = "\n    mutation deleteShortlinkSnoozeTimerWithVars(\n      $ids: [String]\n    ) {\n      deleteShortlinkSnoozeTimer (\n        ids: $ids\n      ) {\n        _id\n        hash\n        location\n        descriptor {\n          userTag\n          descriptionTag\n        }\n      }\n    }\n    ";
                        return [4 /*yield*/, this.gqlClient.request(query, { ids: ids })];
                    case 1:
                        response = _a.sent();
                        console.log('[GQL] deleteShortlinkSnoozeTimer\n', response);
                        return [2 /*return*/, response.deleteShortlinkSnoozeTimer];
                }
            });
        });
    };
    GQLShortlinkQuery.prototype.deleteShortlink = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var query, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        query = "\n    mutation deleteShortlink(\n      $id: String!\n    ) {\n      deleteShortlink (\n        id: $id\n      ) {\n        _id\n        hash\n        location\n        descriptor {\n          userTag\n          descriptionTag\n        }\n      }\n    }\n    ";
                        return [4 /*yield*/, this.gqlClient.request(query, { id: id })];
                    case 1:
                        response = _a.sent();
                        console.log('[GQL] deleteShortlink\n', response);
                        return [2 /*return*/, response.deleteShortlink];
                }
            });
        });
    };
    GQLShortlinkQuery.prototype.updateShortlink = function (id, shortlink) {
        return __awaiter(this, void 0, void 0, function () {
            var query, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        query = "\n    mutation updateShortlinkWithVars(\n      $id: String!\n      $shortlink: QIEditableShortlinkProps\n    ) {\n      updateShortlink (\n        id: $id\n        shortlink: $shortlink\n      ) {\n        ".concat(this.fullShortlinkProperties, "\n      }\n    }\n    ");
                        return [4 /*yield*/, this.gqlClient.request(query, { id: id, shortlink: shortlink })];
                    case 1:
                        response = _a.sent();
                        console.log('[GQL] updateShortlink\n', response);
                        return [2 /*return*/, response.updateShortlink];
                }
            });
        });
    };
    return GQLShortlinkQuery;
}());
export default new GQLShortlinkQuery();
