import styles from './styles-header.less';
import * as React from 'react';
import * as _ from 'underscore';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import Icon, { LogoC, Avatar, IconSize, CaretLeft, Logout, LinkIcon, Snooze } from '../../components/icons';
import { checkMobileMQ } from '../../js/utils';
import AppContext from '../../js/app.context';
import Link from '../../components/link';
import DropdownMenu, { DropdownPosition } from '../../components/dropdown-menu';
import MenuItem from '../../components/menu-item';
import browserApi from '../../js/browser.api';
import config from '../../js/config';
export var HeaderPosition;
(function (HeaderPosition) {
    HeaderPosition[HeaderPosition["sticky"] = 0] = "sticky";
    HeaderPosition[HeaderPosition["fixed"] = 1] = "fixed";
})(HeaderPosition || (HeaderPosition = {}));
var Header = function (_a) {
    var _b;
    var _c, _d, _e;
    var backButton = _a.backButton, title = _a.title, position = _a.position, hideLogo = _a.hideLogo;
    var isMobile = checkMobileMQ();
    var appContext = React.useContext(AppContext);
    var hasLoggedUser = !_.isEmpty(appContext.user);
    console.log(appContext);
    var navigate = useNavigate();
    var _f = React.useState(false), showDropdown = _f[0], setDropdown = _f[1];
    var loginLink = hasLoggedUser ? '/app' : '/login';
    var globalClass = "".concat(styles.wrapperClass, "_app-header");
    var headerClasses = classNames((_b = {},
        _b["".concat(globalClass)] = true,
        _b["".concat(globalClass, "_logged-user")] = hasLoggedUser,
        _b["".concat(globalClass, "_has-avatar")] = !!((_c = appContext.user) === null || _c === void 0 ? void 0 : _c.avatar),
        _b["".concat(globalClass, "_has-back-button")] = !!backButton,
        _b["".concat(globalClass, "_sticky")] = position == HeaderPosition.sticky,
        _b["".concat(globalClass, "_fixed")] = position == HeaderPosition.fixed,
        _b));
    function handleLoginClick(event) {
        if (hasLoggedUser) {
            setDropdown(true);
        }
        else {
            navigate(loginLink);
        }
    }
    function handleLogoutClick() {
        if (config.target == 'extension') {
            browserApi.openExternal("".concat(config.serviceUrl, "/logout"));
        }
        else {
            window.location.href = '/logout';
            setDropdown(false);
        }
    }
    return (React.createElement("div", { className: "".concat(headerClasses) },
        backButton && (React.createElement("div", { className: "".concat(globalClass, "__back_wrapper") },
            React.createElement("div", { onClick: function () { return navigate(backButton); } },
                React.createElement(Icon, { useIcon: CaretLeft, size: IconSize.LARGE })))),
        !hideLogo && React.createElement("div", { className: "".concat(globalClass, "__logo_wrapper"), onClick: function () { return navigate('/'); } },
            React.createElement(LogoC, { className: "".concat(globalClass, "__logo ").concat(globalClass, "__logo_compact") })),
        React.createElement("div", { className: "".concat(globalClass, "__middle") }, title && (React.createElement("div", { className: "".concat(globalClass, "__title_wrapper") },
            React.createElement("span", { className: "".concat(globalClass, "__sub-header") }, "shlk.cc"),
            React.createElement("h1", { className: "".concat(globalClass, "__header") }, title)))),
        React.createElement("div", { className: "".concat(globalClass, "__user") },
            React.createElement(Link, { className: "".concat(globalClass, "__account-link"), isDisabled: false, onClick: handleLoginClick },
                !hasLoggedUser &&
                    (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: "".concat(globalClass, "__account-link__avatar") },
                            React.createElement(Icon, { useIcon: Avatar, size: IconSize.LARGE })),
                        React.createElement("div", { className: "".concat(globalClass, "__account-link__text") }, "Sign in"))),
                hasLoggedUser &&
                    (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: "".concat(globalClass, "__account-link__text") }, appContext.user.name),
                        appContext.user.avatar && React.createElement("div", { className: "".concat(globalClass, "__account-link__avatar"), style: { backgroundImage: "url(".concat(appContext.user.avatar, ")") } }),
                        !appContext.user.avatar && React.createElement("div", { className: "".concat(globalClass, "__account-link__avatar") }, _.first(appContext.user.name.toUpperCase())),
                        React.createElement(DropdownMenu, { className: "".concat(globalClass, "__dropdown"), onClose: function () { return setDropdown(false); }, show: showDropdown, position: [DropdownPosition.top, DropdownPosition.right] },
                            React.createElement("div", { className: "".concat(globalClass, "__dropdown-header") },
                                React.createElement("div", { className: "".concat(globalClass, "__dropdown-header__name-block") },
                                    React.createElement("div", { className: "".concat(globalClass, "__dropdown-header__name-block__name") }, appContext.user.name),
                                    React.createElement("div", { className: "".concat(globalClass, "__dropdown-header__name-block__email") }, appContext.user.email)),
                                ((_d = appContext.user) === null || _d === void 0 ? void 0 : _d.avatar) &&
                                    React.createElement("div", { className: "".concat(globalClass, "__dropdown-header__avatar"), style: { backgroundImage: "url(".concat(appContext.user.avatar, ")") } }),
                                !((_e = appContext.user) === null || _e === void 0 ? void 0 : _e.avatar) &&
                                    React.createElement("div", { className: "".concat(globalClass, "__dropdown-header__avatar") }, _.first(appContext.user.name.toUpperCase()))),
                            React.createElement(MenuItem.Separator, null),
                            React.createElement(MenuItem, { label: 'My shortlinks', icon: LinkIcon, onClick: function () { navigate('/app'); setDropdown(false); } }),
                            React.createElement(MenuItem, { label: 'Snoozed links', icon: Snooze, onClick: function () { navigate('/app/snoozed'); setDropdown(false); } }),
                            React.createElement(MenuItem, { label: 'Profile', icon: Avatar, onClick: function () { navigate('/app/profile'); setDropdown(false); } }),
                            React.createElement(MenuItem.Separator, null),
                            React.createElement(MenuItem, { label: 'Logout', icon: Logout, onClick: handleLogoutClick }))))))));
};
Header.defaultProps = {
    hideLogo: false,
    backButton: ''
};
export default Header;
