import * as _ from 'underscore';
import { valueByPath } from './utils';
import dayjs from 'dayjs';
export default {
    increaseDays: function (value, date) {
        if (date === void 0) { date = new Date(); }
        var result = new Date(date);
        result.setDate(date.getDate() + value);
        return result;
    },
    groupDatedItems: function (items, timestampKey, baseDate) {
        if (baseDate === void 0) { baseDate = new Date(); }
        var result = [];
        _.each(items, function (item) {
            var value = valueByPath(item, timestampKey);
            if (!value)
                return;
            try {
                var itemDate = dayjs(parseInt(value));
                var base = dayjs(baseDate);
                // console.log(value, itemDate)
                if (base.startOf('day') <= itemDate &&
                    itemDate <= base.endOf('day'))
                    result.push(_.extend(item, { group: 'Today' }));
                else if (base.add(1, 'day').startOf('day') <= itemDate &&
                    itemDate <= base.add(1, 'day').endOf('day'))
                    result.push(_.extend(item, { group: 'Tomorrow' }));
                else if (base.subtract(1, 'day').startOf('day') <= itemDate &&
                    itemDate <= base.subtract(1, 'day').endOf('day'))
                    result.push(_.extend(item, { group: 'Yesterday' }));
                else if (itemDate > base &&
                    itemDate <= base.endOf('week'))
                    result.push(_.extend(item, { group: "This ".concat(itemDate.format('dddd')) }));
                else if (base.startOf('year') <= itemDate &&
                    itemDate <= base.endOf('year'))
                    result.push(_.extend(item, { group: "".concat(itemDate.format('ddd, D MMM')) }));
                else
                    result.push(_.extend(item, { group: "".concat(itemDate.format('ddd, D MMM YYYY')) }));
            }
            catch (_a) {
                return;
            }
        });
        return result;
    }
};
