import styles from './styles-page.less';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Link from '../components/link';
import Header from '../apps/Header';
import Footer from '../apps/Footer';
import { useRouter } from './page-hooks';
import Icon, { CaretLeft, IconSize, Google } from '../components/icons';
import Button, { ButtonSize, ButtonType } from '../components/button';
import Video from '../components/video';
import Scroller from '../components/scroller';
var config = require('../js/config');
var Login = function () {
    var globalClass = styles.loginClass + '_login';
    var navigate = useNavigate();
    var router = useRouter();
    return (React.createElement("div", { className: "".concat(globalClass) },
        React.createElement(Header, { backButton: '/', title: 'Log in' }),
        React.createElement("div", { className: "".concat(globalClass, "__layout") },
            React.createElement("div", { className: "".concat(globalClass, "__body") },
                React.createElement(Link, { to: '/', className: "narrow-body__back-button" },
                    React.createElement(Icon, { useIcon: CaretLeft, size: IconSize.LARGE })),
                React.createElement(Scroller, { hideScroll: true, className: "".concat(globalClass, "__scroller") },
                    React.createElement("div", { className: "".concat(globalClass, "__login_content") },
                        React.createElement(Video, { className: "".concat(globalClass, "__video"), thumbnail: "/assets/shlk_logo.jpg", src: [{ link: '/assets/shlk_logo.mp4', type: 'video/mp4' }], aspectRatio: 1200 / 360, timeout: 1000 }),
                        React.createElement("span", { className: "".concat(globalClass, "__intro_text") },
                            "Create account using Google",
                            React.createElement("br", null),
                            "or log into an exiting one"),
                        React.createElement(Button, { href: '/oauth/google', label: 'Log in with Google', size: ButtonSize.LARGE, type: ButtonType.PRIMARY, icon: Google }),
                        React.createElement("span", { className: "".concat(globalClass, "__legal") },
                            "By clicking ",
                            React.createElement("strong", null, "Log in"),
                            ",",
                            React.createElement("br", null),
                            "you agree to our ",
                            React.createElement(Link, { inline: true, to: "/privacy-policy" }, "Privacy policy")))))),
        React.createElement(Footer, null)));
};
export default Login;
