var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import styles from './styles-button.less';
import * as React from 'react';
import * as _ from 'underscore';
import { Flyover } from '../tooltip';
import Icon, { IconSize, CaretRight } from '../icons';
import { useState } from 'react';
import config from '../../js/config';
import browserApi from '../../js/browser.api';
import linkTools from '../../js/link.tools';
import classNames from 'classnames';
export var ButtonSize;
(function (ButtonSize) {
    ButtonSize["LARGE"] = "large";
    ButtonSize["SMALL"] = "small";
})(ButtonSize || (ButtonSize = {}));
export var ButtonType;
(function (ButtonType) {
    ButtonType["PRIMARY"] = "primary";
    ButtonType["GHOST"] = "ghost";
    ButtonType["SECONDARY"] = "secondary";
})(ButtonType || (ButtonType = {}));
var BtnIcnMap = _.object([ButtonSize.LARGE, ButtonSize.SMALL,], [IconSize.LARGE, IconSize.SMALL,]);
var Button = function (args) {
    var _a;
    var _b = useState(false), showFlyover = _b[0], setShowFlyover = _b[1];
    var globalClass = styles.wrapperClass + '_button';
    var buttonClasses = classNames((_a = {},
        _a["".concat(globalClass)] = true,
        _a["".concat(globalClass, "_").concat(args.size)] = true,
        _a["".concat(globalClass, "_").concat(args.type)] = true,
        _a["".concat(globalClass, "_disabled")] = args.isDisabled || args.isLoading,
        _a["".concat(globalClass, "_loading")] = args.isLoading,
        _a["".concat(globalClass, "_icon-only")] = !args.label && args.icon,
        _a["".concat(globalClass, "_full-width")] = args.fullWidth,
        _a["".concat(args.className)] = !!args.className,
        _a));
    var htmlAnchorProps = _.omit(args, 'size', 'type', 'isDisabled', 'isCaret', 'label', 'icon', 'isLoading', 'onClick', 'fullWidth', 'flyover', 'tooltip');
    var btnRef = React.useRef(null);
    var handleClick = function (event) {
        if (args.isDisabled || args.isLoading) {
            event.preventDefault();
            event.stopPropagation();
            return;
        }
        if (_.isFunction(args.onClick)) {
            args.onClick(event, btnRef.current);
        }
        if (config.target == 'extension' && args.href) {
            event.preventDefault();
            var fullUrl = new URL(args.href, linkTools.baseUrl);
            browserApi.openExternal(fullUrl.toString());
        }
        if (args.flyover)
            setShowFlyover(true);
    };
    return (React.createElement("a", __assign({}, htmlAnchorProps, { className: "".concat(buttonClasses), onClick: handleClick, ref: btnRef }),
        args.icon &&
            React.createElement(Icon, { className: "".concat(globalClass, "__icon"), useIcon: args.icon, size: BtnIcnMap[args.size] || IconSize.SMALL }),
        args.label &&
            React.createElement("span", { className: "".concat(globalClass, "__label") },
                args.label,
                args.icon ? React.createElement(React.Fragment, null, "\u00A0") : ''),
        args.isCaret &&
            React.createElement(Icon, { useIcon: CaretRight, size: IconSize.SMALL }),
        args.flyover && showFlyover && React.createElement(Flyover, { label: args.flyover, onDone: function () { return setShowFlyover(false); } })));
};
Button.defaultProps = {
    size: ButtonSize.SMALL,
    type: ButtonType.PRIMARY,
    isDisabled: false,
    isLoading: false,
    isCaret: false,
    fullWidth: false
};
export default Button;
