import styles from './styles-page.less';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Link from '../components/link';
import Header, { HeaderPosition } from '../apps/Header';
import { useRouter } from './page-hooks';
import Icon, { CaretLeft, IconSize } from '../components/icons';
import classNames from 'classnames';
import AppContext from '../js/app.context';
import ShortlinkList from '../apps/ShortlinkList';
import { checkMobileMQ } from '../js/utils';
var config = require('../js/config');
var AppMain = function () {
    var _a;
    var globalClass = styles.appMainClass + '_app-main';
    var shortlinkPageClasses = classNames((_a = {},
        _a["".concat(globalClass)] = true,
        _a));
    var router = useRouter();
    var navigate = useNavigate();
    var appContext = React.useContext(AppContext);
    React.useEffect(function () {
        var _a;
        if (!((_a = appContext.user) === null || _a === void 0 ? void 0 : _a.email)) {
            navigate('/login');
        }
    });
    return (React.createElement("div", { className: "".concat(shortlinkPageClasses) },
        React.createElement(Header, { backButton: '/', title: 'My Links', position: checkMobileMQ() ? HeaderPosition.fixed : undefined }),
        React.createElement("div", { className: "".concat(globalClass, "__layout") },
            React.createElement("div", { className: "".concat(globalClass, "__body") },
                React.createElement(Link, { to: '/', className: "narrow-body__back-button" },
                    React.createElement(Icon, { useIcon: CaretLeft, size: IconSize.LARGE })),
                React.createElement(ShortlinkList, { navigate: navigate, router: router, context: appContext })))));
};
export default AppMain;
