import * as React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import browserApi from '../js/browser.api';
var config = require('../js/config');
export function useRouter() {
    var location = useLocation();
    var navigate = useNavigate();
    var params = useParams();
    return { location: location, navigate: navigate, params: params };
}
export function useExtension() {
    if (!browserApi.isInit || config.target != 'extension')
        return undefined;
    var _a = React.useState(undefined), activeTabUrl = _a[0], setActiveTabUrl = _a[1];
    React.useEffect(function () {
        function deferredStateUpdate(result) {
            if (!result || !result.url || result.url == activeTabUrl)
                return;
            setActiveTabUrl(result.url);
        }
        browserApi.getTab(true).then(deferredStateUpdate);
    });
    return { activeTabUrl: activeTabUrl };
}
