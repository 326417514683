import styles from './styles-snackbar.less';
import * as React from 'react';
import * as _ from 'underscore';
import { Cross_16 } from '../icons';
import { CSSTransition } from 'react-transition-group';
import Link from '../link';
import classNames from 'classnames';
export var SnackbarType;
(function (SnackbarType) {
    SnackbarType["MESSAGE"] = "message";
    SnackbarType["ERROR"] = "error";
    SnackbarType["WARNING"] = "warning";
})(SnackbarType || (SnackbarType = {}));
var Snackbar = function (_a) {
    var _b;
    var _c = _a.type, type = _c === void 0 ? SnackbarType.MESSAGE : _c, _d = _a.canDismiss, canDismiss = _d === void 0 ? false : _d, _e = _a.message, message = _e === void 0 ? '' : _e, action = _a.action, onAction = _a.onAction, onDismiss = _a.onDismiss, timer = _a.timer, className = _a.className;
    var _f = React.useState(true), state = _f[0], setState = _f[1];
    var globalClass = styles.wrapperClass + '_snackbar';
    var snackBarClasses = classNames((_b = {},
        _b["".concat(globalClass)] = true,
        _b["".concat(globalClass, "_has-action")] = !!action,
        _b["".concat(className)] = !!className,
        _b));
    var snackbarNode = React.useRef(null);
    var timeout = parseInt(styles.transitionDuration);
    var timerDivStyle = {
        animationDuration: timer ? "".concat(timer, "ms") : undefined,
    };
    var handleDismiss = function () {
        setState(false);
        _.isFunction(onDismiss) && _.delay(onDismiss, timeout);
    };
    if (timer) {
        React.useEffect(function () {
            var timerDelay = setTimeout(handleDismiss, timer);
            return function () {
                clearTimeout(timerDelay);
            };
        });
    }
    return (React.createElement(CSSTransition, { appear: true, in: state, nodeRef: snackbarNode, timeout: timeout, classNames: "".concat(globalClass, "__transition") },
        React.createElement("div", { ref: snackbarNode, className: "".concat(snackBarClasses) },
            React.createElement("div", { className: "".concat(globalClass, "__content-wrapper") },
                React.createElement("div", { className: "".concat(globalClass, "__message") }, message),
                canDismiss &&
                    React.createElement(Cross_16, { className: "".concat(globalClass, "__dismiss"), onClick: handleDismiss })),
            action &&
                React.createElement(Link, { className: "".concat(globalClass, "__snackbar-action"), label: action, onClick: onAction }),
            timer &&
                React.createElement("div", { className: "".concat(globalClass, "__timer-progress"), style: timerDivStyle }))));
};
export default Snackbar;
