var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import styles from './styles-link.less';
import * as React from 'react';
import * as _ from 'underscore';
import Icon, { IconSize } from '../icons';
import classNames from 'classnames';
import { Flyover } from '../tooltip';
import { Link as RouterLink } from 'react-router-dom';
import config from '../../js/config';
import browserApi from '../../js/browser.api';
import linkTools from '../../js/link.tools';
export var LinkColors;
(function (LinkColors) {
    LinkColors["USER"] = "user";
    LinkColors["APP"] = "app";
})(LinkColors || (LinkColors = {}));
var Link = function (args) {
    var _a, _b, _c;
    var _d = [false, function () { }], showFlyover = _d[0], setShowFlyover = _d[1];
    if (args.flyover)
        _a = React.useState(false), showFlyover = _a[0], setShowFlyover = _a[1];
    var suffixes = args.suffix ? args.suffix.split('+') : [];
    var _e = [false, function () { }], animateSuffix = _e[0], setAnimateSuffix = _e[1];
    if (suffixes.length > 1)
        _b = React.useState(null), animateSuffix = _b[0], setAnimateSuffix = _b[1];
    var globalClass = styles.wrapperClass + '_link';
    var linkClasses = classNames((_c = {},
        _c["".concat(globalClass)] = true,
        _c["".concat(globalClass, "_inline")] = args.inline,
        _c["".concat(globalClass, "_").concat(args.colorScheme)] = true,
        _c["".concat(globalClass, "_loading")] = args.isLoading,
        _c["".concat(globalClass, "_disabled")] = args.isDisabled || args.isLoading,
        _c));
    var handleClick = function (event) {
        if (args.isDisabled || args.isLoading) {
            event.preventDefault();
            event.stopPropagation();
            return;
        }
        if (_.isFunction(args.onClick)) {
            event.preventDefault();
            event.stopPropagation();
            args.onClick(event);
        }
        if (config.target == 'extension' && args.href) {
            event.preventDefault();
            var fullUrl = new URL(args.href, linkTools.baseUrl);
            browserApi.openExternal(fullUrl.toString());
        }
        if (args.flyover)
            setShowFlyover(true);
        if (suffixes.length > 1)
            setAnimateSuffix(true);
    };
    if (args.suffix)
        React.useEffect(function () {
            if (!animateSuffix)
                return;
            var timeout = setTimeout(function () { setAnimateSuffix(false); }, parseInt(styles.swapDuration));
            return function () { clearTimeout(timeout); };
        });
    var inner = (React.createElement(React.Fragment, null,
        args.icon && !args.iconRight &&
            React.createElement(Icon, { useIcon: args.icon, size: args.iconSize || IconSize.SMALL }),
        args.label,
        args.children,
        args.icon && args.iconRight &&
            React.createElement(Icon, { useIcon: args.icon, size: args.iconSize || IconSize.SMALL }),
        args.flyover && showFlyover && React.createElement(Flyover, { label: args.flyover, onDone: function () { return setShowFlyover(false); } }),
        suffixes.length > 0 && (React.createElement(React.Fragment, null,
            React.createElement("span", { className: "".concat(globalClass, "__separator") }, "\u00A0\u00B7\u00A0"),
            React.createElement("span", { className: "".concat(globalClass, "__action-hint") },
                React.createElement("span", { className: "".concat(globalClass, "__action-hint__animated-inner ").concat(animateSuffix ? "".concat(globalClass, "__action-hint__animated-inner-active") : '') },
                    suffixes[0],
                    suffixes[1] && React.createElement(React.Fragment, null,
                        React.createElement("br", null),
                        suffixes[1])))))));
    var htmlAnchorAttributes = _.omit(args, 'colorScheme', 'label', 'icon', 'iconSize', 'isDisabled', 'iconRight', 'isLoading', 'flyover', 'tooltip', 'onClick', 'href', 'to', 'suffix', 'inline');
    if (args.to && args.to != '') {
        return React.createElement(RouterLink, __assign({ to: args.to }, htmlAnchorAttributes, { className: "".concat(linkClasses, " ").concat(args.className || ''), onClick: handleClick }), inner);
    }
    else {
        return React.createElement("a", __assign({ href: args.href }, htmlAnchorAttributes, { className: "".concat(linkClasses, " ").concat(args.className || ''), onClick: handleClick, target: '_blank' }), inner);
    }
};
Link.defaultProps = {
    isDisabled: false,
    isLoading: false,
    colorScheme: LinkColors.APP,
    inline: false
};
export default Link;
