import styles from './styles-menu-item.less';
import * as React from 'react';
import * as _ from 'underscore';
import Icon, { IconSize } from '../icons';
import classNames from 'classnames';
var MenuItem = function (_a) {
    var _b;
    var icon = _a.icon, keepIconSpace = _a.keepIconSpace, label = _a.label, onClick = _a.onClick, isDisabled = _a.isDisabled;
    var globalClass = "".concat(styles.wrapperClass, "_menu-item");
    var menuItemClasses = classNames((_b = {},
        _b["".concat(globalClass)] = true,
        _b["".concat(globalClass, "_disabled")] = !!isDisabled,
        _b["".concat(globalClass, "_keep-space")] = keepIconSpace && !icon,
        _b));
    function handleClick(event) {
        event.stopPropagation();
        if (isDisabled)
            event.preventDefault();
        if (_.isFunction(onClick))
            onClick();
    }
    return (React.createElement("div", { className: "".concat(menuItemClasses), onClick: handleClick },
        icon &&
            React.createElement(Icon, { className: "".concat(globalClass, "__icon"), useIcon: icon, size: IconSize.SMALL }),
        React.createElement("span", { className: "".concat(globalClass, "__label") }, label)));
};
MenuItem.defaultProps = {
    keepIconSpace: false,
    isDisabled: false
};
MenuItem.Separator = function (props) {
    var globalClass = "".concat(styles.wrapperClass, "__separator");
    return (React.createElement("div", { className: globalClass }));
};
export default MenuItem;
