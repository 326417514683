import * as _ from 'underscore';
import constants from './constants';
import './modernizr_build.js';
export function validateURL(str) {
    return constants.regexWeburl.test(str);
}
export function modifyURLSlug(str) {
    str = str.replace(/[^a-z0-9\s-]/ig, '');
    // str = str.replace(/\s+/ig, ' ').trim()
    str = str.replace(/\s/ig, '-');
    return str;
}
export function canShortcutPasteWithKeyboard() {
    return !(Modernizr.touchevents && Modernizr.mq(constants.MediaQueries.mobile));
}
export function checkMobileMQ() {
    return Modernizr.mq(constants.MediaQueries.mobile);
}
export function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
export function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}
export function deleteURLQueryParam(param) {
    var _modifiedSearch = new URLSearchParams(window.location.search);
    _modifiedSearch.delete(param);
    window.location.search = _modifiedSearch.toString();
}
export function valueByPath(object, path) {
    try {
        var result = object;
        for (var i = 0; i < path.length; i++) {
            result = result[path[i]];
        }
        return result;
    }
    catch (_a) {
        return null;
    }
}
export function DOMContentLoaded(fn) {
    if (document.readyState !== 'loading') {
        _.defer(fn);
    }
    else {
        document.addEventListener('DOMContentLoaded', fn);
    }
}
