import * as _ from 'underscore';
import constants from './constants';
import config from './config';
var LinkTools = /** @class */ (function () {
    function LinkTools() {
        this.baseUrl = config.serviceUrl;
        this.displayServiceUrl = config.displayServiceUrl;
    }
    LinkTools.prototype.validateURL = function (str) {
        return constants.regexWeburl.test(str);
    };
    LinkTools.prototype.sanitizeURLSlug = function (str) {
        str = str.replace(/[^a-z0-9\s-]/ig, '');
        str = str.replace(/\s/ig, '-');
        return str;
    };
    LinkTools.prototype.generateShortlinkFromHash = function (hash) {
        return "".concat(this.baseUrl, "/").concat(hash);
    };
    LinkTools.prototype.generateDescriptiveShortlink = function (_a) {
        var userTag = _a.userTag, descriptionTag = _a.descriptionTag;
        var userTagPart = userTag ? userTag : '';
        var descriptionTagPart = '@' + descriptionTag;
        return "".concat(this.baseUrl, "/").concat(userTagPart).concat(descriptionTagPart);
    };
    LinkTools.prototype.fixUrl = function (url) {
        var result = url.trim();
        if (result.indexOf('?') == -1)
            result = result.replace(/\/$/, '');
        if (this.validateURL(url))
            return result;
        if (!/^(https?|ftp):\/\/.*/ig.test(result)) {
            result = 'https://' + result;
            if (this.validateURL(result))
                return result;
        }
        throw new Error("URL ".concat(result, " is not valid"));
    };
    /*
      For query array [ 'param1', 'param2', ... ]
      Returns corresponding query values or null [ 'value1', null, ...  ]
     */
    LinkTools.prototype.queryUrlSearchParams = function (queryParam, searchParamsString) {
        if (!searchParamsString)
            return Array.from({ length: _.size(queryParam) }, function () { return null; });
        var searchParams = new URLSearchParams(searchParamsString);
        var result = [];
        _.forEach(queryParam, function (param) {
            result.push(searchParams.get(param));
        });
        _.map(result, function (item) {
            if (item != null)
                return decodeURIComponent(item);
        });
        return result;
    };
    LinkTools.prototype.makeDisplayUrl = function (rawUrl) {
        var result = (rawUrl || '').trim().replace(/^https?:\/\//ig, '');
        result = result.replace(/^www\./ig, '');
        return result;
    };
    LinkTools.prototype.makeDisplayShortlink = function (prop) {
        if (_.isObject(prop)) {
            var userTagPart = prop.userTag ? prop.userTag : '';
            var descriptionTagPart = '@' + prop.descriptionTag;
            return "".concat(this.displayServiceUrl, "/").concat(userTagPart).concat(descriptionTagPart);
        }
        else {
            return "".concat(this.displayServiceUrl, "/").concat(prop);
        }
    };
    return LinkTools;
}());
export default new LinkTools();
