import React from 'react';
import { createBrowserRouter, createHashRouter } from 'react-router-dom';
import config from './config';
/* PAGES */
import Home from '../pages/home';
import Login from '../pages/login';
import AppMain from '../pages/app-main';
import Profile from '../pages/profile';
import Legal from '../pages/legal';
export default function createRouter() {
    var createRouter = config.target == 'webapp' ? createBrowserRouter : createHashRouter;
    var router = createRouter([
        {
            path: '/',
            element: (React.createElement(Home, null)),
        },
        {
            path: '/login',
            element: (React.createElement(Login, null))
        },
        {
            path: '/app',
            element: (React.createElement(AppMain, null))
        },
        {
            path: '/app/snoozed',
            element: (React.createElement(AppMain, null))
        },
        {
            path: '/app/profile',
            element: (React.createElement(Profile, null))
        },
        {
            path: '/privacy-policy',
            element: (React.createElement(Legal, null))
        }
    ]);
    return router;
}
