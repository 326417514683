var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import styles from './styles-input.less';
import * as React from 'react';
import * as _ from 'underscore';
import classNames from 'classnames';
import Button, { ButtonSize, ButtonType } from '../button';
import Icon, { Cross, IconSize } from '../icons';
var Input = function (props) {
    var _a;
    var debouncedFn = React.useCallback(_.debounce(function (fn) {
        fn();
    }, props.debounce), [props.debounce]);
    var _ref = React.useRef(props.ref);
    var isEmpty = !props.value || props.value == '';
    var globalClass = "".concat(styles.wrapperClass, "_input");
    var inputClasses = classNames((_a = {},
        _a["".concat(globalClass)] = true,
        _a["".concat(globalClass, "_not-empty")] = !isEmpty,
        _a["".concat(globalClass, "_has-left-icon")] = props.leftIcon,
        _a["".concat(globalClass, "_has-right-icon")] = props.rightIcon,
        _a));
    var passedProps = _.omit(props, 'className', 'onChange', 'onDebouncedChange', 'onDeferredChange', 'debounce', 'label', 'placeholder', 'leftIcon', 'rightIcon');
    function handleChange(event) {
        if (_.isFunction(props.onChange))
            props.onChange(event.target.value, event, false);
        if (_.isFunction(props.onDeferredChange))
            _.defer(props.onDeferredChange, event.target.value, event, false);
        if (_.isFunction(props.onDebouncedChange) && props.debounce)
            debouncedFn(function () {
                props.onDebouncedChange(event.target.value, event, false);
            });
    }
    function handleClear(event) {
        if (_.isFunction(props.onChange))
            props.onChange('', event, true);
        if (_.isFunction(props.onDeferredChange))
            _.defer(props.onDeferredChange, '', event, true);
        if (_.isFunction(props.onDebouncedChange) && props.debounce)
            debouncedFn(function () {
                props.onDebouncedChange('', event, true);
            });
    }
    return (React.createElement("label", { className: "".concat(inputClasses) },
        props.label && React.createElement("span", { className: "".concat(globalClass, "__label") }, props.label),
        React.createElement("div", { className: "".concat(globalClass, "__input-wrapper") },
            (props.prefix || props.leftIcon) &&
                React.createElement("div", { className: "".concat(globalClass, "__prefix") },
                    props.leftIcon &&
                        React.createElement(Icon, { size: IconSize.LARGE, className: "".concat(globalClass, "__left-icon"), useIcon: props.leftIcon }),
                    props.prefix),
            React.createElement("div", { className: "".concat(globalClass, "__input-inner") },
                React.createElement("span", { className: "".concat(globalClass, "__placeholder") }, props.placeholder),
                React.createElement("input", __assign({ ref: _ref, className: "".concat(globalClass, "__input-element ").concat(props.className), onChange: handleChange }, passedProps))),
            (props.suffix || props.rightIcon || !isEmpty) &&
                React.createElement("div", { className: "".concat(globalClass, "__suffix") },
                    props.suffix,
                    props.rightIcon &&
                        React.createElement(Icon, { size: IconSize.LARGE, className: "".concat(globalClass, "__right-icon"), useIcon: props.rightIcon }),
                    React.createElement(Button, { className: "".concat(globalClass, "__clear"), size: ButtonSize.SMALL, type: ButtonType.GHOST, icon: Cross, onClick: handleClear })))));
};
export default Input;
Input.defaultProps = {
    debounce: 100,
    autoComplete: 'off'
};
