import styles from './styles-footer.less';
import * as React from 'react';
import Flag from '../../assets/svg/flag_fi_16.svg';
import Heart from '../../assets/svg/w_love_14.svg';
import Link from '../../components/link';
var Footer = function () {
    var globalClass = styles.wrapperClass + '_footer';
    return (React.createElement("div", { className: "".concat(globalClass, "__wrapper") },
        React.createElement("div", { className: "".concat(globalClass) },
            React.createElement("div", { className: "".concat(globalClass, "__item") },
                "Personal project by ",
                React.createElement(Link, { href: "https://portfolio.designpr.one" }, "designpr.one"),
                "\u00B7 PP Mori typeface ",
                React.createElement(Heart, { className: "".concat(globalClass, "__with-love-icon") })),
            React.createElement("div", { className: "".concat(globalClass, "__item") },
                React.createElement("span", null, "Valmistettu Suomessa"),
                React.createElement(Flag, { className: "".concat(globalClass, "__flag-icon") })))));
};
export default Footer;
