import styles from './styles-page.less';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Link from '../components/link';
import Header, { HeaderPosition } from '../apps/Header';
import Footer from '../apps/Footer';
import UserSettings from '../apps/UserSettings';
import { useRouter } from './page-hooks';
import Icon, { CaretLeft, IconSize } from '../components/icons';
import classNames from 'classnames';
import AppContext from '../js/app.context';
import Scroller from '../components/scroller';
var Profile = function (_a) {
    var _b;
    var router = useRouter();
    var navigate = useNavigate();
    var appContext = React.useContext(AppContext);
    var globalClass = styles.profileClass + '_profile';
    var profileClasses = classNames((_b = {},
        _b["".concat(globalClass)] = true,
        _b));
    React.useEffect(function () {
        var _a;
        if (!((_a = appContext.user) === null || _a === void 0 ? void 0 : _a.email)) {
            navigate('/login');
        }
    });
    return (React.createElement("div", { className: "".concat(profileClasses) },
        React.createElement(Header, { backButton: '/', title: 'My profile', position: HeaderPosition.sticky }),
        React.createElement("div", { className: "".concat(globalClass, "__layout") },
            React.createElement("div", { className: "".concat(globalClass, "__body") },
                React.createElement(Link, { to: '/', className: "narrow-body__back-button" },
                    React.createElement(Icon, { useIcon: CaretLeft, size: IconSize.LARGE })),
                React.createElement(Scroller, null,
                    React.createElement(UserSettings, { className: "".concat(globalClass, "__profile-content"), router: router, context: appContext })))),
        React.createElement(Footer, null)));
};
export default Profile;
