import styles from './styles-radio-group.less';
import * as React from 'react';
import Icon, { IconSize } from '../icons';
import classNames from 'classnames';
var RadioGroup = function (_a) {
    var _b;
    var items = _a.items, onChange = _a.onChange, value = _a.value, fullWidth = _a.fullWidth;
    var globalClass = "".concat(styles.wrapperClass, "_radio-group");
    var radioGroupClasses = classNames((_b = {},
        _b["".concat(globalClass)] = true,
        _b["".concat(globalClass, "_full-width")] = fullWidth,
        _b));
    function handleClick(event, key) {
        onChange(key);
    }
    // const styleObj = fullWidth ? { width: `${100%}`}
    return (React.createElement("div", { className: "".concat(radioGroupClasses) }, items.map(function (item, index) {
        var activeClass = item.key == value ? "".concat(globalClass, "__radio-button_active") : '';
        return (React.createElement("div", { className: "".concat(globalClass, "__radio-button ").concat(activeClass), onClick: function (event) { return handleClick(event, item.key); }, key: item.key },
            item.icon && React.createElement(Icon, { useIcon: item.icon, size: IconSize.SMALL }),
            item.label));
    })));
};
RadioGroup.defaultProps = {
    fullWidth: false
};
export default RadioGroup;
