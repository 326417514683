import styles from './styles-snooze-list.less';
import * as _ from 'underscore';
import * as React from 'react';
import Link from '../link';
import classNames from 'classnames';
import AppContext from '../../js/app.context';
var SnoozeList = function (_a) {
    var _b;
    var onSnooze = _a.onSnooze;
    var globalClass = "".concat(styles.wrapperClass, "_snooze-list");
    var snoozeClasses = classNames((_b = {},
        _b["".concat(globalClass)] = true,
        _b));
    var appContext = React.useContext(AppContext);
    function displayTime(value, standardSnooze) {
        var now = new Date();
        var setDate = new Date(value);
        var preDate = '';
        var actualTime = '';
        if (standardSnooze && /random/.test(standardSnooze)) {
            preDate = '~3 months';
        }
        else if (standardSnooze &&
            !(/today/.test(standardSnooze) ||
                /tomorrow/.test(standardSnooze))) {
            preDate = setDate.toLocaleDateString([], { month: 'short', day: 'numeric' }) + ', ';
        }
        if (standardSnooze && !/random/.test(standardSnooze)) {
            actualTime = setDate.toLocaleTimeString(['en-GB'], { hour: '2-digit', minute: '2-digit' });
        }
        return preDate + actualTime;
    }
    function displayDate(value) {
        return (new Date(value)).toLocaleDateString([], { weekday: 'short', month: 'short', day: 'numeric' });
    }
    var groupedTimers = _.groupBy(appContext.user.predefinedTimers, 'groupLabel');
    console.log(groupedTimers);
    var groups = Object.getOwnPropertyNames(groupedTimers);
    return (React.createElement("div", { className: "".concat(snoozeClasses) },
        React.createElement("div", { className: "".concat(globalClass, "__subheader") }, "Snooze link until:"),
        React.createElement("div", { className: "".concat(globalClass, "__wrapper") }, groups.map(function (groupProp, key) {
            var group = groupedTimers[groupProp];
            return (React.createElement("div", { className: "".concat(globalClass, "__group ").concat(globalClass, "__group_").concat(key), key: key },
                React.createElement("div", { className: "".concat(globalClass, "__group__subheader") },
                    React.createElement("span", { className: "".concat(globalClass, "__group__subheader__label") }, group[0].groupLabel),
                    React.createElement("span", { className: "".concat(globalClass, "__group__subheader__meta") },
                        group[0].groupDate[0] && ' · ' + displayDate(group[0].groupDate[0]),
                        group[0].groupDate[1] && '–' + displayDate(group[0].groupDate[1]))),
                React.createElement("div", { className: "".concat(globalClass, "__group-bullet") }),
                React.createElement("div", { className: "".concat(globalClass, "__group-line") }),
                React.createElement("div", { className: "".concat(globalClass, "__group__wrapper") }, group.map(function (timer, key) {
                    return (React.createElement("div", { className: "".concat(globalClass, "__item"), key: key },
                        React.createElement(Link, { className: "".concat(globalClass, "__link"), onClick: function () { return onSnooze(timer.value); }, key: key },
                            React.createElement("span", { className: "".concat(globalClass, "__link__label") }, timer.label),
                            React.createElement("span", { className: "".concat(globalClass, "__link__time") }, displayTime(timer.dateValue, timer.value)))));
                }))));
        }))));
};
export default SnoozeList;
