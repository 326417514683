import styles from './styles-history.less';
import * as React from 'react';
import * as _ from 'underscore';
import clipboardTools from '../../js/clipboard.tools';
import Link, { LinkColors } from '../../components/link';
import LinkTools from '../../js/link.tools';
import classNames from 'classnames';
import Button, { ButtonSize, ButtonType } from '../../components/button';
import { checkMobileMQ } from '../../js/utils';
var ActionLabels;
(function (ActionLabels) {
    ActionLabels["copy"] = "Copy";
    ActionLabels["copied"] = "Copied";
    ActionLabels["open"] = "Open";
})(ActionLabels || (ActionLabels = {}));
var HistoryWidget = function (_a) {
    var _b;
    var list = _a.list, totalCount = _a.totalCount, isLoading = _a.isLoading, display = _a.display, context = _a.context, router = _a.router;
    var globalClass = styles.widgetWrapper + '_history-widget';
    var widgetClasses = classNames((_b = {},
        _b["".concat(globalClass)] = true,
        _b));
    var loadingSkeletons = _.range(display);
    var handleClick = function (url, key, event) {
        if (clipboardTools.enabled)
            clipboardTools.copy(url);
    };
    if (totalCount == 0 && !isLoading)
        return (React.createElement(React.Fragment, null));
    return (React.createElement("div", { className: "".concat(widgetClasses) },
        React.createElement("div", { className: "".concat(globalClass, "__header") }, "Last created shortlinks"),
        React.createElement("div", { className: "".concat(globalClass, "__link-list") },
            !isLoading && list.map(function (item, key) {
                var _a, _b;
                if (!item.hash)
                    return null;
                var shortlink = ((_a = item.descriptor) === null || _a === void 0 ? void 0 : _a.descriptionTag) && item.descriptor.descriptionTag != '' ?
                    LinkTools.generateDescriptiveShortlink(item.descriptor) :
                    LinkTools.generateShortlinkFromHash(item.hash);
                var displayShortlink = ((_b = item.descriptor) === null || _b === void 0 ? void 0 : _b.descriptionTag) && item.descriptor.descriptionTag != '' ?
                    LinkTools.makeDisplayShortlink(item.descriptor) :
                    LinkTools.makeDisplayShortlink(item.hash);
                var url = item.location;
                var displayUrl = LinkTools.makeDisplayUrl(item.location);
                return (React.createElement("div", { className: "".concat(globalClass, "__link-block"), key: key },
                    React.createElement(Link, { onClick: function (event) { return handleClick(shortlink, key, event); }, className: "".concat(globalClass, "__shortlink"), suffix: "".concat(ActionLabels.copy, "+").concat(ActionLabels.copied) },
                        React.createElement("span", { className: "".concat(globalClass, "__shortlink__label") }, displayShortlink)),
                    React.createElement(Link, { className: "".concat(globalClass, "__full-link"), href: url, colorScheme: LinkColors.USER },
                        React.createElement("span", { className: "".concat(globalClass, "__full-link__label") }, displayUrl))));
            }),
            isLoading && loadingSkeletons.map(function (_, index) {
                return (React.createElement("div", { className: "".concat(globalClass, "__link-block ").concat(globalClass, "__loading"), key: index },
                    React.createElement("div", { className: "".concat(globalClass, "__shortlink") },
                        React.createElement("span", { className: "".concat(globalClass, "__shortlink__label") }, "\u00A0")),
                    React.createElement("div", { className: "".concat(globalClass, "__full-link") },
                        React.createElement("span", { className: "".concat(globalClass, "__full-link__label") }, "\u00A0"))));
            }),
            router && checkMobileMQ() &&
                React.createElement(Button, { className: "".concat(globalClass, "__all-links"), label: "Show all links", type: ButtonType.SECONDARY, size: ButtonSize.SMALL, fullWidth: true, onClick: function () { return router.navigate('/app'); } }))));
};
HistoryWidget.defaultProps = {
    list: [],
    totalCount: 0,
    isLoading: false,
    display: 3
};
export default HistoryWidget;
