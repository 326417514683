var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/* eslint-disable @typescript-eslint/ban-types */
import styles from './styles-shortlink-bar.less';
import { checkMobileMQ, modifyURLSlug, validateURL, setCookie, getCookie } from '../../js/utils';
import * as React from 'react';
import * as _ from 'underscore';
import HeroInput from '../../components/hero-input/index';
import ShortlinkDisplay from '../../components/shortlink-display';
import ShortlinkSlugInput from '../../components/shortlink-slug-input';
import Snackbar from '../../components/snackbar';
import SnoozeList from '../../components/snooze-list';
import linkTools from '../../js/link.tools';
import clipboardTools from '../../js/clipboard.tools';
import Query from '../../js/shortlink.gql';
import Cache from '../../js/cache';
import AppContext from '../../js/app.context';
import { HistoryWidget } from '../History';
import browserApi from '../../js/browser.api';
import Video from '../../components/video';
import Footer from '../../apps/Footer';
import Link from '../../components/link';
var config = require('../../js/config');
var globalCommands;
(function (globalCommands) {
    globalCommands["submitAndCopy"] = "submit_copy";
    globalCommands["snooze"] = "snooze";
})(globalCommands || (globalCommands = {}));
var ShortlinkBar = /** @class */ (function (_super) {
    __extends(ShortlinkBar, _super);
    function ShortlinkBar(props) {
        var _this = this;
        var _a, _b;
        _this = _super.call(this, props) || this;
        _this.heroInputRef = React.createRef();
        var predefinedLocation = linkTools.queryUrlSearchParams(['l'], (_b = (_a = props.router) === null || _a === void 0 ? void 0 : _a.location) === null || _b === void 0 ? void 0 : _b.search)[0];
        _this.state = {
            location: _.unescape(predefinedLocation || ''),
            generatedShortlink: undefined,
            generatedDescriptiveShortlink: undefined,
            generatedHash: undefined,
            userTag: _this.defaultUserTag(),
            descriptionTag: '',
            errorState: {},
            loadingState: {
                createLinkIsLoading: false,
                createDescriptiveLinkIsLoading: false
            },
            successState: {},
            mobileConvenienceInput: false,
            cachedShortlinks: [],
            cachedShortlinksLoading: false,
            cachedShortlinksDisplayNumber: checkMobileMQ() ? 2 : 3,
            showSnoozeOptions: false
        };
        _.bindAll.apply(_, __spreadArray([_this], _.functions(_this), false));
        return _this;
        // this.submitDescriptor = _.debounce(this._submitDescriptor, 500)
    }
    ShortlinkBar.prototype.componentDidMount = function () {
        var _a, _b;
        if (this.heroInputRef.current &&
            (!checkMobileMQ() || config.target == 'extension'))
            this.heroInputRef.current.focus();
        if (validateURL(this.state.location)) {
            this.submitLocation();
        }
        if (this.state.location) {
            this._setMobileConvenienceInput(true);
        }
        this.handleGlobalEvents(true);
        this.loadAllCachedShortlinks();
        if ((_b = (_a = this.props.context) === null || _a === void 0 ? void 0 : _a.extension) === null || _b === void 0 ? void 0 : _b.activeTabUrl)
            this.setState({
                location: this.props.context.extension.activeTabUrl
            });
    };
    ShortlinkBar.prototype.componentWillUnmount = function () {
        this.handleGlobalEvents(false);
    };
    ShortlinkBar.prototype.handleGlobalEvents = function (bind) {
        if (bind === void 0) { bind = true; }
        console.log('global event binder ', bind);
        if (bind) {
            window.addEventListener('keypress', this.onGlobalKeypress);
        }
        else {
            window.removeEventListener('keypress', this.onGlobalKeypress);
        }
    };
    ShortlinkBar.prototype.onGlobalKeypress = function (event) {
        console.log(event);
        if ((event.ctrlKey || event.metaKey) && event.code == 'KeyD') {
            event.preventDefault();
            event.stopPropagation();
            this.handleGlobalCommand(globalCommands.submitAndCopy);
        }
        else if ((event.ctrlKey || event.metaKey) && event.code == 'KeyS') {
            event.preventDefault();
            event.stopPropagation();
            this.handleGlobalCommand(globalCommands.snooze);
        }
    };
    ShortlinkBar.prototype.componentDidUpdate = function () {
        if (this.state.location &&
            config.target == 'extension')
            this._setMobileConvenienceInput(true);
    };
    ShortlinkBar.prototype.updateLocation = function (newLocation, isClearPress) {
        if (isClearPress === void 0) { isClearPress = false; }
        var keepSnoozeOptionsOpen = newLocation != '' && this.state.showSnoozeOptions;
        this._clearErrorState();
        this.setState({
            location: newLocation.trim(),
            generatedShortlink: undefined,
            generatedDescriptiveShortlink: undefined,
            generatedHash: undefined,
            userTag: this.defaultUserTag(),
            descriptionTag: '',
            showSnoozeOptions: keepSnoozeOptionsOpen
        });
        if (isClearPress)
            this._setMobileConvenienceInput(false);
    };
    ShortlinkBar.prototype.handleGlobalCommand = function (command) {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        console.log('global command:', command);
                        _a = command;
                        switch (_a) {
                            case globalCommands.submitAndCopy: return [3 /*break*/, 1];
                            case globalCommands.snooze: return [3 /*break*/, 3];
                        }
                        return [3 /*break*/, 4];
                    case 1: return [4 /*yield*/, this.submitLocation()];
                    case 2:
                        _b.sent();
                        this.setState({
                            successState: {
                                successMessage: 'Shortlink copied to clipboard'
                            }
                        });
                        clipboardTools.copy(this.state.generatedShortlink || '');
                        return [2 /*return*/];
                    case 3:
                        {
                            this.setState({
                                showSnoozeOptions: true
                            });
                        }
                        _b.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ShortlinkBar.prototype.setShortlinkState = function (args) {
        console.log("[Display new shortlink]", args);
        var newState = {
            generatedShortlink: linkTools.generateShortlinkFromHash(args.hash),
            generatedHash: args.hash,
            location: args.location,
            errorState: {}
        };
        if (args.descriptionTag) {
            newState = __assign(__assign({}, newState), { userTag: args.userTag, descriptionTag: args.descriptionTag, generatedDescriptiveShortlink: linkTools.generateDescriptiveShortlink({ userTag: args.userTag, descriptionTag: args.descriptionTag || '' }) });
        }
        this.setState(newState);
        _.defer(this.saveLSCache);
    };
    ShortlinkBar.prototype.retrieveLSCache = function () {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function () {
            var locationUrl, cachedURL;
            return __generator(this, function (_e) {
                locationUrl = linkTools.fixUrl(this.state.location.trim());
                cachedURL = Cache.checkShortlink({ location: locationUrl });
                console.log('[Trying cache...]', locationUrl, window.localStorage[locationUrl]);
                if (cachedURL == null || !cachedURL.hash)
                    return [2 /*return*/, false];
                if (this.state.userTag != ((_a = cachedURL.descriptor) === null || _a === void 0 ? void 0 : _a.userTag) ||
                    (this.state.descriptionTag != '' && this.state.descriptionTag != ((_b = cachedURL.descriptor) === null || _b === void 0 ? void 0 : _b.descriptionTag)))
                    return [2 /*return*/, false];
                console.log('[Cache → Retrieved object]:\n', cachedURL);
                this.setShortlinkState({
                    location: cachedURL.location,
                    hash: cachedURL.hash,
                    userTag: (_c = cachedURL.descriptor) === null || _c === void 0 ? void 0 : _c.userTag,
                    descriptionTag: (_d = cachedURL.descriptor) === null || _d === void 0 ? void 0 : _d.descriptionTag
                });
                return [2 /*return*/, true];
            });
        });
    };
    ShortlinkBar.prototype.saveLSCache = function () {
        if (!this.state.generatedHash)
            console.error('Empty hash to be saved!');
        var descriptor = this.state.descriptionTag ?
            { userTag: this.state.userTag, descriptionTag: this.state.descriptionTag } :
            undefined;
        Cache.storeShortlink({
            location: this.state.location.trim(),
            hash: this.state.generatedHash,
            descriptor: descriptor
        });
        this.loadAllCachedShortlinks();
    };
    ShortlinkBar.prototype.loadAllCachedShortlinks = function () {
        return __awaiter(this, void 0, void 0, function () {
            var storage;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        Cache.setStorage();
                        this.setState({
                            cachedShortlinks: [],
                            cachedShortlinksLoading: true,
                            cachedShortlinksDisplayNumber: checkMobileMQ() ? 2 : 3
                        });
                        return [4 /*yield*/, Cache.awaitStorage()];
                    case 1:
                        storage = _a.sent();
                        this.setState({
                            cachedShortlinks: _.first(storage, this.state.cachedShortlinksDisplayNumber),
                            cachedShortlinksLoading: false,
                            cachedShortlinksDisplayNumber: checkMobileMQ() ? 2 : 3
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    ShortlinkBar.prototype.submitLocation = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var locationUrl, cachedResult, result, error_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this._clearErrorState();
                        this.snoozeOptions(false);
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 4, , 5]);
                        locationUrl = linkTools.fixUrl(this.state.location.trim());
                        if (!locationUrl)
                            return [2 /*return*/];
                        return [4 /*yield*/, this.retrieveLSCache()];
                    case 2:
                        cachedResult = _c.sent();
                        if (cachedResult)
                            return [2 /*return*/];
                        this.setState({ loadingState: { createLinkIsLoading: true } });
                        return [4 /*yield*/, Query.createShortlink(locationUrl)];
                    case 3:
                        result = _c.sent();
                        console.log('[Home → submitLocation]\n', result);
                        if (!result || !result.hash)
                            throw new Error("Unexpected error: shortlink for '".concat(locationUrl, "' was not created. Please, try again"));
                        this.setShortlinkState({
                            location: result.location,
                            hash: result.hash,
                            userTag: ((_a = result.descriptor) === null || _a === void 0 ? void 0 : _a.userTag) || '',
                            descriptionTag: ((_b = result.descriptor) === null || _b === void 0 ? void 0 : _b.descriptionTag) || ''
                        });
                        return [3 /*break*/, 5];
                    case 4:
                        error_1 = _c.sent();
                        this.setState({ errorState: {
                                lastError: error_1 || undefined,
                                createLinkResult: true
                            }
                        });
                        return [3 /*break*/, 5];
                    case 5:
                        this.setState({ loadingState: { createLinkIsLoading: false } });
                        return [2 /*return*/];
                }
            });
        });
    };
    ShortlinkBar.prototype.snoozeOptions = function (val) {
        if (val === void 0) { val = true; }
        this.setState({
            showSnoozeOptions: val
        });
    };
    ShortlinkBar.prototype.handleSuccessPaste = function (clipText) {
        this._clearErrorState();
        this.setState({
            location: clipText
        });
        this.submitLocation();
    };
    ShortlinkBar.prototype.handleDescriptorChange = function (value) {
        this._clearErrorState();
        this.setState({
            descriptionTag: modifyURLSlug(value),
            loadingState: { createDescriptiveLinkIsLoading: true }
        });
    };
    // public submitDescriptor: (() => void) & _.Cancelable;
    ShortlinkBar.prototype.submitDescriptor = function () {
        return __awaiter(this, void 0, void 0, function () {
            var result, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._clearErrorState();
                        console.log('[Home → submitDescriptor]\n', this.state.userTag, this.state.descriptionTag);
                        if (this.state.userTag)
                            setCookie('userTag', this.state.userTag, 365);
                        if (!this.state.descriptionTag) {
                            this.setState({
                                generatedDescriptiveShortlink: undefined,
                                loadingState: { createDescriptiveLinkIsLoading: false }
                            });
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.retrieveLSCache()];
                    case 1:
                        if (_a.sent())
                            return [2 /*return*/];
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, Query.createShortlinkDescriptor({ userTag: this.state.userTag,
                                descriptionTag: this.state.descriptionTag,
                                location: this.state.location,
                                hash: this.state.generatedHash
                            })];
                    case 3:
                        result = _a.sent();
                        if (!result || !result.descriptor)
                            return [2 /*return*/];
                        if (result.descriptor.descriptionTag != this.state.descriptionTag)
                            return [2 /*return*/];
                        this.setShortlinkState({
                            location: result.location,
                            hash: result.hash,
                            userTag: result.descriptor.userTag,
                            descriptionTag: result.descriptor.descriptionTag
                        });
                        return [3 /*break*/, 5];
                    case 4:
                        error_2 = _a.sent();
                        this.setState({ errorState: {
                                lastError: error_2 || undefined,
                                createDescriptiveLinkResult: true
                            }
                        });
                        return [3 /*break*/, 5];
                    case 5:
                        this.setState({ loadingState: { createDescriptiveLinkIsLoading: false } });
                        return [2 /*return*/];
                }
            });
        });
    };
    ShortlinkBar.prototype._clearErrorState = function () {
        this.setState({ errorState: {
                lastError: undefined,
                createDescriptiveLinkResult: this.state.errorState.createDescriptiveLinkResult
            } });
    };
    ShortlinkBar.prototype._clearSuccessState = function () {
        this.setState({ successState: {} });
    };
    ShortlinkBar.prototype.defaultUserTag = function () {
        return getCookie('userTag');
    };
    ShortlinkBar.prototype._setMobileConvenienceInput = function (mode) {
        if (checkMobileMQ() && this.state.mobileConvenienceInput != mode) {
            this.setState({ mobileConvenienceInput: mode });
            if (_.isFunction(this.props.onToggleHeaderDisplay))
                this.props.onToggleHeaderDisplay(mode);
            _.delay(function () { return document.body.scrollTo(0, 0); }, 1000);
        }
    };
    ShortlinkBar.prototype._onHeroInputElementFocus = function (event) {
        this._setMobileConvenienceInput(true);
    };
    ShortlinkBar.prototype.handleStandardSnooze = function (predefinedValue) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var baseDateISOString, location_1, result, trimLocation, description, error_3;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        baseDateISOString = (new Date()).toISOString();
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        location_1 = linkTools.fixUrl(this.state.location);
                        return [4 /*yield*/, Query.createOrUpdateShortlinkTimer({
                                baseDateISOString: baseDateISOString,
                                location: location_1,
                                hash: this.state.generatedHash,
                                standardTimer: predefinedValue
                            })];
                    case 2:
                        result = _b.sent();
                        if (!result)
                            return [2 /*return*/, null];
                        this.updateLocation('');
                        trimLocation = result.location.length > 30 ? result.location.slice(0, 29) + '…' : result.location;
                        description = (((_a = result.snooze) === null || _a === void 0 ? void 0 : _a.description) || '').toLowerCase();
                        this.setState({
                            successState: {
                                successMessage: "Snoozed until ".concat(description, ": ").concat(trimLocation)
                            }
                        });
                        if (browserApi.isInit) {
                            browserApi.closeActiveTab();
                            browserApi.sendMessage({ command: 'sync' });
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        error_3 = _b.sent();
                        this.setState({ errorState: {
                                lastError: error_3 || undefined,
                                createLinkResult: true
                            }
                        });
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ShortlinkBar.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f;
        var globalClass = styles.homepage + '_app-body';
        var mobileConvenienceClass = this.state.mobileConvenienceInput ? '__mobile-convenience-state' : '';
        return (React.createElement("div", { className: "".concat(globalClass) },
            React.createElement("div", { className: "".concat(globalClass, "__layout") },
                React.createElement("div", { className: "".concat(globalClass, "__shortlink-block ").concat(mobileConvenienceClass) },
                    React.createElement("div", { className: "".concat(globalClass, "__offset-wrapper") },
                        !this.state.mobileConvenienceInput &&
                            React.createElement(Video, { className: "".concat(globalClass, "__video"), thumbnail: "/assets/shlk_logo.jpg", src: [{ link: '/assets/shlk_logo.mp4', type: 'video/mp4' }], aspectRatio: 1200 / 360, timeout: 1000 }),
                        React.createElement(HeroInput, { inputRef: this.heroInputRef, onChange: this.updateLocation, onSubmit: this.submitLocation, onSnooze: function () { return _this.snoozeOptions(true); }, name: "URL", placeholder: "Type or paste a link", value: this.state.location, onFocus: this._onHeroInputElementFocus, hasCta: !this.state.generatedShortlink || this.state.generatedShortlink == '' })),
                    !this.state.showSnoozeOptions && (React.createElement(React.Fragment, null,
                        React.createElement(ShortlinkDisplay, { placeholder: config.displayServiceUrl, shortlink: this.state.generatedShortlink, isLoading: this.state.loadingState.createLinkIsLoading, hasCta: (!!this.state.generatedShortlink || this.state.generatedShortlink != '') && (!this.state.generatedDescriptiveShortlink), error: this.state.errorState.createLinkResult }),
                        this.state.generatedShortlink &&
                            React.createElement(ShortlinkSlugInput, { displayLink: linkTools.displayServiceUrl, userTag: ((_b = (_a = this.props.context) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.userTag) ? (_d = (_c = this.props.context) === null || _c === void 0 ? void 0 : _c.user) === null || _d === void 0 ? void 0 : _d.userTag : 'someone', value: this.state.descriptionTag, placeholder: "your-custom-url", onDeferredChange: this.handleDescriptorChange, onDebouncedChange: this.submitDescriptor, debounce: 500, show: this.state.generatedShortlink ? true : false, generatedLink: this.state.generatedDescriptiveShortlink, isLoading: this.state.loadingState.createDescriptiveLinkIsLoading, hasCta: !this.state.generatedDescriptiveShortlink || this.state.generatedDescriptiveShortlink != '', error: this.state.errorState.createDescriptiveLinkResult, flyover: React.createElement("div", { className: "".concat(globalClass, "__flyover") },
                                    ((_e = this.props.context) === null || _e === void 0 ? void 0 : _e.user) &&
                                        React.createElement("div", { className: "".concat(globalClass, "__logged-content") },
                                            "You can choose different name in ",
                                            React.createElement(Link, { inline: true, to: '/app/profile' }, "Profile")),
                                    !((_f = this.props.context) === null || _f === void 0 ? void 0 : _f.user) &&
                                        React.createElement("div", { className: "".concat(globalClass, "__anonymous-content") },
                                            "You can make it unique by ",
                                            React.createElement(Link, { inline: true, to: '/login' }, "creating\u00A0an\u00A0account"))) }))),
                    this.state.showSnoozeOptions && (React.createElement(SnoozeList, { onSnooze: this.handleStandardSnooze })),
                    React.createElement("div", { className: "".concat(globalClass, "__snackbar-container") },
                        this.state.errorState.lastError &&
                            React.createElement(Snackbar, { message: this.state.errorState.lastError.message, canDismiss: true, onDismiss: this._clearErrorState }),
                        this.state.successState.successMessage &&
                            React.createElement(Snackbar, { message: this.state.successState.successMessage, canDismiss: true, timer: 2000, onDismiss: this._clearSuccessState }))),
                React.createElement("div", { className: "".concat(globalClass, "__footer-wrapper") },
                    React.createElement(HistoryWidget, { list: this.state.cachedShortlinks, totalCount: this.state.cachedShortlinks.length, isLoading: this.state.cachedShortlinksLoading, display: this.state.cachedShortlinksDisplayNumber, context: this.props.context, router: this.props.router }))),
            React.createElement(Footer, null)));
    };
    return ShortlinkBar;
}(React.Component));
export default ShortlinkBar;
ShortlinkBar.contextType = AppContext;
