import styles from './styles-tooltip.less';
import * as React from 'react';
/*
  @TODO
*/
var Tooltip = function (_a) {
    var label = _a.label;
    var globalClass = styles.tooltipClass + '_tooltip';
    return (React.createElement("div", { className: "".concat(globalClass) }, label));
};
export default Tooltip;
