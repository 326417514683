import styles from './styles-icons.less';
import * as React from 'react';
import LogoSvg from '../../assets/svg/logo.svg';
import LogoCSvg from '../../assets/svg/logo-compact.svg';
import AvatarSvg from '../../assets/svg/icon/avatar.svg';
import CaretRightSvg from '../../assets/svg/icon/caret-right.svg';
import CaretLeftSvg from '../../assets/svg/icon/caret-left.svg';
import CrossSvg from '../../assets/svg/icon/cross.svg';
import CrossSvg_16 from '../../assets/svg/icon/cross_16.svg';
import EnterSvg from '../../assets/svg/icon/enter.svg';
import GoogleSvg from '../../assets/svg/icon/google.svg';
import SearchSvg from '../../assets/svg/icon/search.svg';
import SnoozeSvg from '../../assets/svg/icon/snooze.svg';
import LogoutSvg from '../../assets/svg/icon/logout.svg';
import CaretDownSvg from '../../assets/svg/icon/caret-down.svg';
import LinkSvg from '../../assets/svg/icon/link.svg';
import MoreVSvg from '../../assets/svg/icon/more.svg';
import CompactSvg from '../../assets/svg/icon/compact.svg';
import FullSvg from '../../assets/svg/icon/full.svg';
export var Logo = LogoSvg;
export var LogoC = LogoCSvg;
export var Avatar = AvatarSvg;
export var CaretRight = CaretRightSvg;
export var CaretLeft = CaretLeftSvg;
export var CaretDown = CaretDownSvg;
export var Cross = CrossSvg;
export var Enter = EnterSvg;
export var Google = GoogleSvg;
export var Snooze = SnoozeSvg;
export var Search = SearchSvg;
export var Logout = LogoutSvg;
export var LinkIcon = LinkSvg;
export var MoreVertical = MoreVSvg;
export var FullIcon = FullSvg;
export var CompactIcon = CompactSvg;
export var Cross_16 = CrossSvg_16;
export var IconSize;
(function (IconSize) {
    IconSize["SMALL"] = "small";
    IconSize["LARGE"] = "large";
})(IconSize || (IconSize = {}));
var Icon = function (_a) {
    var useIcon = _a.useIcon, size = _a.size, className = _a.className;
    var globalClass = 'icon-svg';
    var IconNode = useIcon;
    var propClass = className || '';
    return (React.createElement("div", { className: "".concat(styles.wrapperClass, " ").concat(globalClass, " ").concat(globalClass, "_size-").concat(size, " ").concat(propClass) },
        React.createElement(IconNode, { className: "".concat(globalClass, "__node") })));
};
export default Icon;
