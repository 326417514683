import styles from './styles-tooltip.less';
import * as React from 'react';
import { CSSTransition } from 'react-transition-group';
var Flyover = function (_a) {
    var label = _a.label, onDone = _a.onDone;
    var ref = React.useRef(null);
    var globalClass = styles.flyoverClass + '_flyover';
    var animDuration = {
        appear: parseInt(styles.flyoverDuration) + parseInt(styles.opacityEndDuration)
    };
    return (React.createElement(CSSTransition, { appear: true, in: true, exit: false, mountOnEnter: true, unmountOnExit: true, timeout: animDuration, classNames: 'flyover-animation', nodeRef: ref, onEntered: onDone },
        React.createElement("div", { className: "".concat(globalClass), ref: ref }, label)));
};
export default Flyover;
