import styles from './styles-shortlink-display.less';
import * as React from 'react';
import Link, { LinkColors } from '../link';
import Button, { ButtonSize, ButtonType } from '../button';
import clipboardTools from '../../js/clipboard.tools';
import { Flyover } from '../tooltip';
import classNames from 'classnames';
import LinkTools from '../../js/link.tools';
export var ShortlinkDisplay = function (_a) {
    var _b;
    var placeholder = _a.placeholder, hashLength = _a.hashLength, shortlink = _a.shortlink, _c = _a.isLoading, isLoading = _c === void 0 ? false : _c, _d = _a.hasCta, hasCta = _d === void 0 ? true : _d, _e = _a.error, error = _e === void 0 ? false : _e;
    var _f = React.useState(false), showFlyover = _f[0], setShowFlyover = _f[1];
    function copyOnClick() {
        if (shortlink && clipboardTools.enabled) {
            clipboardTools.copy(shortlink);
            setShowFlyover(true);
        }
    }
    var globalClass = styles.wrapperClass + '_shortlink-display';
    var shortlinkClasses = classNames((_b = {},
        _b["".concat(globalClass)] = true,
        _b["".concat(globalClass, "_empty")] = !shortlink,
        _b["".concat(globalClass, "_error")] = error,
        _b));
    var placeholderText = (React.createElement(React.Fragment, null,
        placeholder,
        "/",
        React.createElement("span", { className: globalClass + '__text_placeholder-spacing' }, '••••')));
    var linkLabel = 'Copy shortlink';
    var btnLabel = 'Copy';
    if (isLoading)
        linkLabel = 'Loading';
    var displayShortlink = shortlink ? LinkTools.makeDisplayUrl(shortlink) : null;
    var activeActionWrapperClass = shortlink ? globalClass + '__action-wrapper_has-shortlink' : '';
    var placeholderLoadingClass = isLoading ? globalClass + '__text_loading' : '';
    return (React.createElement("div", { className: "".concat(shortlinkClasses) },
        React.createElement("div", { className: "".concat(globalClass, "__content-wrapper") },
            React.createElement("div", { className: "".concat(globalClass, "__label") }, "Get your shortened link"),
            React.createElement("div", { className: "".concat(globalClass, "__action-wrapper ").concat(activeActionWrapperClass, " link-block"), onClick: copyOnClick },
                React.createElement("span", { className: "".concat(globalClass, "__text ").concat(placeholderLoadingClass) }, shortlink ? displayShortlink : placeholderText),
                shortlink &&
                    React.createElement(Link, { className: "".concat(globalClass, "__copy-pseudolink"), colorScheme: LinkColors.APP, flyover: 'Copied!' },
                        linkLabel,
                        showFlyover && React.createElement(Flyover, { label: 'Copied!', onDone: function () { return setShowFlyover(false); } })))),
        (isLoading || shortlink) &&
            React.createElement(Button, { className: "".concat(globalClass, "__copy-button"), label: btnLabel, size: ButtonSize.LARGE, type: hasCta ? ButtonType.PRIMARY : ButtonType.SECONDARY, isDisabled: !shortlink, isLoading: isLoading, flyover: 'Copied!' })));
};
export default ShortlinkDisplay;
