var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import styles from './styles-scroller.less';
import classNames from 'classnames';
import * as React from 'react';
import * as _ from 'underscore';
var Scroller = function (args) {
    var _a;
    var wrapperRef = React.useRef();
    var contentRef = React.useRef();
    var prevScrollheight = React.useRef(-1);
    var _b = React.useState(-1), scrollPos = _b[0], setScrollPos = _b[1];
    var _c = React.useState({}), scrollbarStyles = _c[0], setScrollbarStyles = _c[1];
    var globalClass = "".concat(styles.wrapperClass, "_scroller");
    var scrollerClasses = classNames((_a = {},
        _a["".concat(globalClass)] = true,
        _a["".concat(args.className)] = !!args.className,
        _a));
    var internalScroll = function (event) {
        var scrollTop = contentRef.current.scrollTop;
        var scrollHeight = contentRef.current.scrollHeight;
        var clientHeight = contentRef.current.clientHeight;
        if (args.hideScroll)
            return;
        if (scrollHeight <= clientHeight) {
            if (scrollPos != -1)
                setScrollPos(-1);
            return;
        }
        if (scrollTop == scrollPos && scrollHeight == prevScrollheight.current)
            return;
        if (_.isFunction(args.onScroll) && event)
            args.onScroll(scrollTop, scrollHeight, clientHeight, scrollTop - scrollPos);
        prevScrollheight.current = scrollHeight;
        setScrollPos(scrollTop);
        setScrollbarStyles({ top: calcScrollbarTop(scrollPos), height: calcScrollbarHeight() });
    };
    var calcScrollbarTop = function (scrollTop) {
        var clientHeight = contentRef.current.clientHeight;
        var scrollHeight = contentRef.current.scrollHeight;
        var effectiveHeight = clientHeight - (args.offsetBottom + args.offsetTop + 2 * args.padding);
        return Math.ceil((scrollTop / scrollHeight) * effectiveHeight) + args.offsetTop + args.padding;
    };
    var calcScrollbarHeight = function () {
        var clientHeight = contentRef.current.clientHeight;
        var scrollHeight = contentRef.current.scrollHeight;
        var effectiveHeight = clientHeight - (args.offsetBottom + args.offsetTop + 2 * args.padding);
        return Math.ceil(effectiveHeight * (effectiveHeight / scrollHeight));
    };
    React.useEffect(function () {
        internalScroll();
    });
    var transientProps = _.omit(args, 'onScroll', 'ref', 'className', 'padding', 'offsetTop', 'offsetBottom', 'hideScroll');
    return (React.createElement("div", __assign({ className: "".concat(scrollerClasses) }, transientProps),
        React.createElement("div", { className: "".concat(globalClass, "__scroller-wrapper"), ref: wrapperRef },
            React.createElement("div", { className: "".concat(globalClass, "__scroller-content"), ref: contentRef, onScroll: internalScroll }, args.children)),
        scrollPos >= 0 &&
            React.createElement("div", { className: "".concat(globalClass, "__scrollbar") },
                React.createElement("div", { className: "".concat(globalClass, "__scrollbar__pill"), style: scrollbarStyles }))));
};
Scroller.defaultProps = {
    padding: 8,
    offsetTop: 0,
    offsetBottom: 0
};
export default Scroller;
