import styles from './styles-shortlink-slug-input.less';
import * as _ from 'underscore';
import * as React from 'react';
import Link, { LinkColors } from '../link';
import Button, { ButtonSize, ButtonType } from '../button';
import classNames from 'classnames';
export var SlugInputSpecialChars;
(function (SlugInputSpecialChars) {
    SlugInputSpecialChars["mobileLineBreak"] = "__mobileLineBreak__";
    SlugInputSpecialChars["lineBreak"] = "__lineBreak__";
})(SlugInputSpecialChars || (SlugInputSpecialChars = {}));
export var ShortlinkSlugInput = function (_a) {
    var _b;
    var onChange = _a.onChange, onDebouncedChange = _a.onDebouncedChange, debounce = _a.debounce, onDeferredChange = _a.onDeferredChange, show = _a.show, isLoading = _a.isLoading, generatedLink = _a.generatedLink, hasCta = _a.hasCta, error = _a.error, displayLink = _a.displayLink, userTag = _a.userTag, value = _a.value, placeholder = _a.placeholder, flyover = _a.flyover;
    var debouncedFn = React.useCallback(_.debounce(function (fn) {
        fn();
    }, debounce), [debounce]);
    var globalClass = styles.wrapperClass + '_slug-input';
    var slugInputClasses = classNames((_b = {},
        _b["".concat(globalClass)] = true,
        _b["".concat(globalClass, "_error")] = error,
        _b["".concat(globalClass, "_hide")] = !show,
        _b));
    var activeActionWrapperClass = generatedLink ? globalClass + '__action-wrapper_has-shortlink' : '';
    var linkLabel = 'Copy custom shortlink';
    if (!generatedLink)
        linkLabel = '';
    if (isLoading)
        linkLabel = 'Loading';
    var btnLabel = 'Copy';
    if (isLoading)
        linkLabel = 'Loading';
    function handleCopy() {
        if (_.isFunction(navigator.clipboard.writeText) && generatedLink) {
            navigator.clipboard.writeText(generatedLink);
        }
    }
    function handleChange(event) {
        if (_.isFunction(onChange))
            onChange(event.target.value);
        if (_.isFunction(onDeferredChange))
            _.defer(onDeferredChange, event.target.value);
        if (_.isFunction(onDebouncedChange) && debounce)
            debouncedFn(function () {
                onDebouncedChange(event.target.value);
            });
    }
    return (React.createElement("div", { className: "".concat(slugInputClasses) },
        React.createElement("div", { className: "".concat(globalClass, "__label") }, "Make a custom link"),
        React.createElement("div", { className: "".concat(globalClass, "__content-wrapper") },
            React.createElement("div", { className: "".concat(globalClass, "__action-wrapper ").concat(activeActionWrapperClass), onClick: handleCopy },
                React.createElement("div", { className: "".concat(globalClass, "__constructed-input") },
                    React.createElement("span", { className: "".concat(globalClass, "_text-filler ").concat(globalClass, "__input-common-style") },
                        displayLink,
                        "/"),
                    React.createElement("div", { className: "".concat(globalClass, "_text-filler ").concat(globalClass, "__input-common-style ").concat(globalClass, "__user-tag") },
                        userTag,
                        flyover &&
                            React.createElement("div", { className: "".concat(globalClass, "__flyover") },
                                React.createElement("div", { className: "".concat(globalClass, "__flyover__content") }, flyover))),
                    React.createElement("div", { className: "".concat(globalClass, "_text-filler ").concat(globalClass, "__input-common-style") }, "@"),
                    React.createElement("br", { className: "".concat(globalClass, "__mlbr") }),
                    React.createElement("span", { className: "".concat(globalClass, "__input-resizable") },
                        React.createElement("input", { className: "".concat(globalClass, "__input-resizable__real-input ").concat(globalClass, "__input-common-style"), value: value, onChange: handleChange }),
                        React.createElement("span", { className: "".concat(globalClass, "__input-resizable__width-sizer ").concat(globalClass, "__input-common-style ").concat(globalClass, "__input-resizable__width-sizer_").concat(value ? 'hide' : 'show') }, value || placeholder))),
                React.createElement(Link, { className: "".concat(globalClass, "__copy_pseudolink"), colorScheme: LinkColors.APP, isDisabled: !generatedLink || error, isLoading: isLoading, label: linkLabel, flyover: 'Copied!' })),
            (isLoading || generatedLink) &&
                React.createElement(Button, { className: "".concat(globalClass, "__copy_button"), label: btnLabel, size: ButtonSize.LARGE, type: hasCta ? ButtonType.PRIMARY : ButtonType.SECONDARY, isDisabled: !generatedLink || error, isLoading: isLoading, onClick: handleCopy, flyover: 'Copied!' }))));
};
ShortlinkSlugInput.defaultProps = {
    show: true,
    isLoading: false,
    hasCta: true,
    error: false,
    value: '',
    placeholder: 'type-your-custom-value'
};
export default ShortlinkSlugInput;
