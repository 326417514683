import classNames from 'classnames';
import styles from './styles-video.less';
import * as React from 'react';
import * as _ from 'underscore';
var Video = function (_a) {
    var _b;
    var src = _a.src, thumbnail = _a.thumbnail, width = _a.width, height = _a.height, className = _a.className, aspectRatio = _a.aspectRatio, timeout = _a.timeout, muted = _a.muted;
    var _c = React.useState(false), loaded = _c[0], setLoaded = _c[1];
    var videoNode = React.useRef();
    var globalClass = "".concat(styles.wrapperClass, "_video");
    var videoClasses = classNames((_b = {},
        _b["".concat(globalClass)] = true,
        _b["".concat(className)] = !!className,
        _b));
    var initLazyVideo = function () {
        setLoaded(true);
        _.defer(function () {
            var _a;
            (_a = videoNode === null || videoNode === void 0 ? void 0 : videoNode.current) === null || _a === void 0 ? void 0 : _a.load();
        });
        _.delay(function () {
            var _a;
            (_a = videoNode === null || videoNode === void 0 ? void 0 : videoNode.current) === null || _a === void 0 ? void 0 : _a.play();
        }, timeout);
    };
    React.useEffect(function () {
        if (!loaded)
            _.defer(initLazyVideo);
    });
    var responsiveVideoClass = !(width && height) && aspectRatio ? "".concat(globalClass, "__video-node_responsive") : '';
    var inlineStyle = aspectRatio ? { aspectRatio: aspectRatio } : {};
    return (React.createElement("div", { className: "".concat(videoClasses) }, src.length > 0 &&
        React.createElement("video", { poster: thumbnail, ref: videoNode, className: "".concat(globalClass, "__video-node ").concat(responsiveVideoClass), autoPlay: false, muted: muted, controls: false, preload: "none", playsInline: true, height: height, width: width, style: inlineStyle }, src.map(function (sourceObj, key) {
            return React.createElement("source", { src: loaded ? sourceObj.link : '', type: sourceObj.type, key: key });
        }))));
};
Video.defaultProps = {
    timeout: 100,
    muted: true
};
export default Video;
