import styles from './styles-page.less';
import * as React from 'react';
import Header from '../apps/Header';
import ShortlinkBar from '../apps/ShortlinkBar';
import { useExtension, useRouter } from './page-hooks';
import AppContext from '../js/app.context';
import Scroller from '../components/scroller';
var config = require('../js/config');
var Home = function () {
    var _a = React.useState(true), visibleHeader = _a[0], setVisibleHeader = _a[1];
    var globalClass = styles.homeClass + '_home';
    var router = useRouter();
    var extension = config.target == 'extension' ? useExtension() : undefined;
    var context = React.useContext(AppContext);
    return (React.createElement("div", { className: "".concat(globalClass, " ").concat(visibleHeader ? "" : "".concat(globalClass, "_no-header")) },
        visibleHeader && React.createElement(Header, { hideLogo: true }),
        React.createElement(Scroller, { className: "".concat(globalClass, "__body"), hideScroll: true },
            React.createElement(ShortlinkBar, { router: router, extension: extension, context: context, onToggleHeaderDisplay: function (notVisible) { return setVisibleHeader(!notVisible); } }))));
};
export default Home;
